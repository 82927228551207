/* this file added by Bearer Web Developer  */
import { Button, Dialog } from "@material-ui/core";
import React, { useState } from "react";
import styles from './changePasswordPopup.module.scss';
import BearerTextFieldPassword from "../../../components/fields/BearerTextFieldPassword";
import { useAppState } from "../../../states/app-state";
import { Observer } from "mobx-react-lite";
import DisabledClickLoading from "../../../components/DisabledClickLoading";

interface IChangePasswordPopupProps { open: boolean, handleClose: () => void }
const ChangePasswordPopup: React.FC<IChangePasswordPopupProps> = ({ open, handleClose }) => {
    const appState = useAppState();
    const [passwordError, setPasswordError] = useState('');
    const [repeatPassword, setRepeatpassword] = useState({ error: '', value: '' });
    const [loading, setLoading] = useState(false);
    const handleRepeatPassword = (value: string) => {
        if (value.length === 0) {
            return 'Repeat Password is required';
        } else if (value !== appState.login.newPassword) {
            return 'Repeat Password is not the same as the New Password';
        } else return '';
    }
    const handlePassword = (value: string) => {
        if (value.length === 0) return "New Password is required";
        if (!value.match(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/))
            return "Minimum 8 characters, at least 1 Uppercase letter, 1 Lowercase letter, 1 Number and 1 Special character"
        // if (value.length < 3) {
        //     return "New Password must be at least three characters";
        // }
        return '';
    }


    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        const valid = !!appState.login.newPassword && !!repeatPassword.value
            && appState.login.newPassword === repeatPassword.value;
        if (valid) {
            setLoading(true);
            const result = await appState.login.fetchChangePassword();
            setLoading(false);
            if (!result.error) {
                await appState.login.logout();
                window.location.reload();
            } else {
                if (result.error.message === "Firebase: Password should be at least 6 characters (auth/weak-password).") {
                    setPasswordError("Password should be at least 6 characters");
                } else {
                    setPasswordError(result.error.message);
                }
            }
        }
    }
    return (
        <Dialog open={open} onClose={handleClose} PaperProps={{ className: styles.root,style: { borderRadius: "10px" }  }}>
            <form onSubmit={handleSubmit} className={styles.change_password}>
                <div className={styles.header}>
                    <h5>Change your password</h5>
                    <div>
                        <Button onClick={handleClose}>
                            <img src="/back.png" alt="back" />
                        </Button>
                    </div>
                </div>
                <p>Please enter your new password</p>
                <Observer>{() => {
                    const valid = !!appState.login.newPassword && !!repeatPassword.value
                        && appState.login.newPassword === repeatPassword.value;
                    return (
                        <>
                        <div className={styles.password_input}>
                            <BearerTextFieldPassword
                                value={appState.login.newPassword}
                                name="new-password"
                                label="New Password"
                                placeholder="New Password"
                                autoComplete="new-password"
                                onChange={(e) => {
                                    // e.target.value 
                                    appState.login.setNewPasword(e.target.value)
                                }}
                                // onKeyPress={handleKeyPress}
                                validator={handlePassword}
                                errorField={passwordError}
                                colorStyle="gray"
                            />
                        </div>
                        <div className={styles.repeat_password_input}>
                            <BearerTextFieldPassword
                                value={repeatPassword.value}
                                name="repeat-password"
                                label="Repeat Password"
                                placeholder="Repeat Password"
                                autoComplete="repeat-password"
                                onChange={(e) => { setRepeatpassword(prev => ({ ...prev, value: e.target.value })) }}
                                // onKeyPress={handleKeyPress}
                                validator={handleRepeatPassword}
                                colorStyle="gray"
                            />
                            <Button className={styles.submit} disabled={!valid} type='submit'>Confirm</Button>
                            {loading && <DisabledClickLoading />}
                        </div>
                        </>
                    )
                }}
                </Observer>
            </form>
        </Dialog>
    )
}
export default ChangePasswordPopup;