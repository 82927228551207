/* this file added by Bearer Web Developer  */
import React from 'react';
import { Button, TextFieldProps } from '@material-ui/core';
import styles from './BearerCopyTextField.module.scss'; // added by Bearer Web Developer
type IColorStyle = "gray" | "green";
type BearerTextFieldProps = TextFieldProps & { colorStyle?: IColorStyle } & { secret?: boolean }; // extended by Bearer Web Developer
// inputProperties interface added by Bearer Web Developer
interface inputProperties {
    label?: string
    value?: string
    checked?: boolean
    onChange?: () => {}
    onClick?: () => {}
    disabled?: boolean
    placeholder?: string
    name?: string
}
const BearerCopyTextField: React.FC<BearerTextFieldProps> = ({ colorStyle, secret, ...props }) => {

    const copyTextRef: React.RefObject<HTMLInputElement> | null = React.createRef();
    function CopyTextField() {
        // Get the text field
        const copyText = copyTextRef?.current;
        if (copyText) {
            // Select the text field
            copyText?.select();
            copyText?.setSelectionRange(0, 99999); // For mobile devices

            // Copy the text inside the text field
            navigator.clipboard.writeText(copyText?.value);

            // Alert the copied text
            console.log("Copied the text: " + copyText?.value);
        }
    }
    // modified by Bearer Web Developer start
    const { inputProps, label, name, placeholder, ...otherProps } = props;
    return (
        <div className={styles.text_input}>
            <div className={`${colorStyle ? styles?.[colorStyle] : styles.gray}`}>
                <div className={styles.label}>
                    <label htmlFor={name || label?.toString()}>{label}</label>
                </div>
                <div className={styles.input_part}>
                    <input {...inputProps} name={name || label?.toString()} readOnly={true} ref={copyTextRef}
                        id={name || label?.toString()} type={secret ? "password" : "text"}
                        {...otherProps as inputProperties} placeholder={placeholder} />
                    <Button className={styles.copy} type='button' 
                    onClick={CopyTextField} classes={{label:styles.copy_button_label}}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                            <path d="M9 18C8.45 18 7.97917 17.8042 7.5875 17.4125C7.19583 17.0208 7 16.55 7 16V4C7 3.45 7.19583 2.97917 7.5875 2.5875C7.97917 2.19583 8.45 2 9 2H18C18.55 2 19.0208 2.19583 19.4125 2.5875C19.8042 2.97917 20 3.45 20 4V16C20 16.55 19.8042 17.0208 19.4125 17.4125C19.0208 17.8042 18.55 18 18 18H9ZM9 16H18V4H9V16ZM5 22C4.45 22 3.97917 21.8042 3.5875 21.4125C3.19583 21.0208 3 20.55 3 20V6H5V20H16V22H5Z" fill="#4D4D4D" />
                        </svg>
                        <span>copy</span>
                    </Button>
                </div>
            </div>
        </div>
    );
    // modified by Bearer Web Developer end
}
export default BearerCopyTextField;