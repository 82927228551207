/* this file added by Bearer Web Developer  */
import React, { useContext } from 'react';
import styles from './name.module.scss';
import { ReservedOrderInfoContext } from '../contexts/reservedOrderInfo';

const Name: React.FC = () => {
    const reservedOrderDetails = useContext(ReservedOrderInfoContext);

    return (
        <div className={styles.name}>
            <p>
                <img src="/person-grey.webp" alt="" width={21} height={21} />
                <span>Recipient’s Name: {reservedOrderDetails?.recipient_name}</span>
            </p>
        </div>
    )
}

export default Name;