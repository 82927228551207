/* this file added by Bearer Web Developer  */
import React from 'react';
import styles from './secretGeneration.module.scss';
import BearerCopyTextField from '../../../../fields/BearerCopyTextField';
import ApiDevProvider from './apiDevProvider';
import ApiRequestsOrigin from './apiRequestsOrigin';
import { useAppState } from '../../../../../states/app-state';
import { ApiIntegrationEnum } from '../../../../../states/apiIntegrationState/models/apiIntegrationEnum';
const SecretGeneration: React.FC = () => {
    const appState = useAppState();
    const apiIntegrationData = appState?.ApiIntegration?.apiIntegrationData;
    const regenerateStep = appState?.ApiIntegration?.apiIntegrationStep === ApiIntegrationEnum.RegenerateApiIntegration;
    const afterRegenerateStep = appState?.ApiIntegration?.apiIntegrationStep === ApiIntegrationEnum.AfterRegenerateApiIntegration;
    return (
        <div className={styles.secret_generation}>
            <div className={styles.copy_text_field_part}>
                <div className={styles.copy_text_field}>
                    <BearerCopyTextField label={"API Key"} value={apiIntegrationData?.api_key} />
                </div>
                {
                    (!regenerateStep || (afterRegenerateStep)) &&
                    <div className={styles.copy_text_field}>
                        <BearerCopyTextField label={"API Secret"} value={apiIntegrationData?.api_secret} colorStyle='green' secret={true} />
                    </div>
                }
            </div>
            <div className={styles.add}>
                <ApiDevProvider />
                <ApiRequestsOrigin />
            </div>
        </div>
    )
}

export default SecretGeneration;