import React, { useState } from 'react';
import { observer } from 'mobx-react-lite';
import { Dialog, Button, Link, CircularProgress } from '@material-ui/core';
import { useAppState } from '../states/app-state';
import styles from './Notifier.module.scss';

const Notifier: React.FunctionComponent<IProps> = observer((props) => {
  const appStore = useAppState();

  const { snackMessage } = appStore;
  // modified by Bearer Web Developer (add handleClick with loading) start
  const [loading, setLoading] = useState(false);
  const handleClickaSync = async () => {
    if (snackMessage?.handleClick && snackMessage?.hasLoadingForHandleClick) {
      setLoading(true);
      await snackMessage?.handleClick()
      setLoading(false);
      appStore.setSnackMessage(undefined);
    }
  }
  const hide = () => {
    // handleClick added by Bearer Web Developer start
    if (snackMessage?.handleClick) {
      if (snackMessage?.hasLoadingForHandleClick) {
        handleClickaSync();
      } else {
        snackMessage?.handleClick();
        appStore.setSnackMessage(undefined);
      }
    } else {
      appStore.setSnackMessage(undefined);
    }
    // handleClick added by Bearer Web Developer end
    
  };
  // modified by Bearer Web Developer (add handleClick with loading) end

  if (!snackMessage) {
    return null;
  }

  return (
    // modified by Bearer Web Developer start
    <Dialog open={true} PaperProps={{ className: styles.root, style: { borderRadius: "10px" } }} >
      <p>{snackMessage.message}</p>
      <div className={styles.buttons}>
        <Button onClick={hide} className={styles.button}>
          {loading ? <CircularProgress /> : <span>{snackMessage?.buttonMessage || "OK"}</span>}
        </Button> {/* modified by Bearer Web Developer*/}
        {/* added by Bearer Web Developer start */}
        {snackMessage?.hasSopportLink &&
          <Link className={styles.support} target="_blank" href={appStore.webAppRemoteConfigParameters?.supportUrl}><span>Support</span></Link>
        }
        {/* added by Bearer Web Developer end */}
      </div>
    </Dialog>
    // modified by Bearer Web Developer end
    // <Snackbar
    //   anchorOrigin={{
    //     vertical: 'bottom',
    //     horizontal: 'left',
    //   }}
    //   open={true}
    //   autoHideDuration={6000}
    //   onClose={hide}
    //   ContentProps={{
    //     'aria-describedby': 'message-id',
    //   }}
    //   message={snackMessage.message}
    //   action={[
    //     <IconButton
    //       key="close"
    //       aria-label="Close"
    //       color="inherit"
    //       className={classes.close}
    //       onClick={hide}
    //     >
    //       <CloseIcon />
    //     </IconButton>,
    //   ]}
    // />
  );
});

interface IProps {
}

export default (Notifier);
