import React from "react";
import { Button } from "@material-ui/core";
import { Observer, observer, useLocalObservable } from "mobx-react-lite";
import RightRadioButton from "../../components/rightradiobutton/RightRadioButton";
import BearerTextField from "../../components/fields/BearerTextField";
import { ReceiveReceiptState, SelectEmailTransportReceiptEnum, useAppState } from "../../states/app-state";
import { TTransportsHistoryInfo } from "../../api/bearer-api";
import DisabledClickLoading from "../../components/DisabledClickLoading";
import styles from './ReceiveReceiptPopup.module.scss'; // added by Bearer Web Developer
// useStyles removed by Bearer Web Developer


interface IReceiveReceiptPopupProps { transportsHistory: TTransportsHistoryInfo }
const ReceiveReceiptPopup: React.FC<IReceiveReceiptPopupProps> = observer(({ transportsHistory }) => {
    const appState = useAppState();
    const state = useLocalObservable(() => ReceiveReceiptState.create({ myEmail: appState.login.email }))
    const [loading, setLoading] = React.useState(false);
    const handleConfirm = async () => {
        setLoading(true);
        const email = state.email;
        await appState.transportHistory.emailTransportReceipt(transportsHistory.transport_id, email);
        setLoading(false);
    }
    // modified by Bearer Web Developer start
    return (
        <div className={styles.root}>
            <div>
                <h6>Receive the Receipt</h6>
            </div>
            <div className={styles.receipt}>

                <p>Email the receipt to:</p>

                <div>
                    <RightRadioButton
                         gray={true}
                        label={state.myEmail}
                        value={SelectEmailTransportReceiptEnum.MyEmail}
                        checked={state.selectEmailTransportReceipt === SelectEmailTransportReceiptEnum.MyEmail}
                        onClick={() => state.setSelectEmailTransportReceipt(SelectEmailTransportReceiptEnum.MyEmail)}
                    />
                </div>
                <div>
                    <RightRadioButton
                          gray={true}
                        label="Other"
                        value={SelectEmailTransportReceiptEnum.Another}
                        checked={state.selectEmailTransportReceipt === SelectEmailTransportReceiptEnum.Another}
                        onClick={() => state.setSelectEmailTransportReceipt(SelectEmailTransportReceiptEnum.Another)}
                    />
                </div>
            </div>
            <div className={styles.email_input}>
                <BearerTextField
                    placeholder="email@example.com"
                    colorStyle="gray"
                    label="Email Address"
                    disabled={state.selectEmailTransportReceipt !== SelectEmailTransportReceiptEnum.Another}
                    value={state.anotherEmail}
                    onChange={(e) => state.setAnotherEmail(e.target.value)}
                    clear={()=>{ state.setAnotherEmail('') }} // added by Bearer Web Developer
                    validator={validateEmail}
                />
            </div>
            <div>
                <Observer>{() => {
                    const valid = !!state.selectEmailTransportReceipt && !!state.email.trim()
                    return (
                        <Button
                            className={styles.submit}
                            onClick={handleConfirm}
                            disabled={!valid}
                        >
                            <span>Submit</span>
                        </Button>
                    )
                }}</Observer>
                {loading && <DisabledClickLoading />}
            </div>
        </div>
    )
    // modified by Bearer Web Developer end
})

export default ReceiveReceiptPopup;


function validateEmail(value: string) {
    if (!(value.match(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/))) return 'Email address is not valid!'
}