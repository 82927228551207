/* this file added by Bearer Web Developer  */
import React from 'react';
import styles from './time.module.scss';
import { useAppState } from '../../../../../../../../states/app-state';

const Time: React.FC = () => {
    const appState = useAppState();
    const activeManageOrder = appState?.ReservedOrders?.activeManageOrder;
    const miliseconds = Number(activeManageOrder?.transport_creation_time);
    const date = new Date(miliseconds)?.toLocaleDateString();
    const time = new Date(miliseconds)?.toLocaleTimeString();
    return (
        <div className={styles.time}>
            <div className={styles.date}>
                <img src="/calendar-month-grey.webp" alt="" width={20} height={20} />
                <span>{date}</span>
            </div>
            <div className={styles.time_value}>
                <img src="/schedule-grey.webp" alt="" width={20} height={20} />
                <span>{time}</span>
            </div>
        </div>
    )
}

export default Time;