/* this file added by Bearer Web Developer  */
import React, { useContext } from 'react';
import styles from './expireTime.module.scss';
import { useAppState } from '../../../../../../../../states/app-state';


const ExpireTime: React.FC = () => {
    const appState = useAppState();
    const activeManageOrder = appState?.ReservedOrders?.activeManageOrder;
    const miliseconds = Number(activeManageOrder?.reservation_expiry_time);
    const expiryTime = new Date(miliseconds).toLocaleDateString();
    return (
        <div className={styles.expire_time}>
            <h4>Expiry time:</h4>
            <span>{expiryTime}</span>
        </div>
    )
}

export default ExpireTime;