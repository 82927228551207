import React from 'react';
import { Button } from '@material-ui/core';
import { IOrderState, ITransportState, TransportTypeEnum, useAppState } from '../../states/app-state';
import { analytics, TTransportOption } from '../../api/bearer-api';
import { observer } from 'mobx-react-lite';
import TransportTypeImage from './TransportTypeImage';
import LoginPopup from '../login/LoginPopup';
import DisabledClickLoading from '../../components/DisabledClickLoading';
import { error } from '../../app/log'; // added by Bearer Web Developer
import styles from './TransportsEditor.module.scss'; // added by Bearer Web Developer
import { logEvent } from 'firebase/analytics'; // added by Bearer Web Developer
import ReactPixel from 'react-facebook-pixel'; // added by Bearer Web Developer
type TTransport = {
    type: TransportTypeEnum,
    option: TTransportOption | null | undefined,
}
// useStyles removed by Bearer Web Developer
const TransportsEditor: React.FC<{ transport: ITransportState }> = ({ transport }) => {

    const appState = useAppState();
    const selectedOrder = appState.selectedOrder!;

    const [logInPopupOpen, setLogInPopupOpen] = React.useState(false);

    const handleLoginInPopupClose = () => {
        setLogInPopupOpen(false);
    }

    const [loading, setLoading] = React.useState(false);
    const handleConfirm = async () => {
        // added by Bearer Web Developer start
        // Firebase Analytics Event Name Mode of Transport Chosen (Final)
        // App Event Mode of Transport Confirmed by User
        if (appState.selectedOrder?.selectedTransportOption) {
            logEvent(analytics, "selected_mode_of_transport", {
                mode_of_transport: appState.selectedOrder?.selectedTransportOption?.type
            });
        }
        // the fallowing "AddToCart" (Facebook Ads default event)
        if (appState?.environmentCode === "PRODUCTION") {
            ReactPixel.trackSingle('3587653761499122', 'AddToCart'); // For tracking custom events.
        }
        // added by Bearer Web Developer end
        if (!appState.login.loggedin) {
            setLogInPopupOpen(true)
        }
        else {
            setLoading(true);
            const r = await appState.selectedOrder?.fetchTransport();
            setLoading(false);
            r && transport.setEditing(false);
            // added by Bearer Web Developer start
            if (r) {
                const { is_full_discount, fullDiscountUsedOnce, coupon_id } = appState?.fullDiscountAutomatically;
                if (is_full_discount && !fullDiscountUsedOnce) {
                    selectedOrder?.discount.setFullDiscountUser(true);
                    selectedOrder?.discount?.setDiscountValue({
                        coupon_id, transport_receivable_price: 0
                    });
                }
            }
            // added by Bearer Web Developer end
        }
    }

    // added by Bearer Web Developer start
    const getTransports = () => (
        [
            {
                type: TransportTypeEnum.riding,
                option: selectedOrder.transportOptions.riding,
            },
            {
                type: TransportTypeEnum.cycling,
                option: selectedOrder.transportOptions.cycling,
            },
            {
                type: TransportTypeEnum.walking,
                option: selectedOrder.transportOptions.walking,
            },
        ]
    )
    const [transports, setTransports] = React.useState<TTransport[]>(getTransports());
    const fetchPricingData = async () => {
        try {
            setLoading(true);
            const r = await appState.selectedOrder?.fetchPricing();
            setLoading(false);
            if (r) {
                setTransports(getTransports())
            }
        } catch (err) {
            error(err) // modify by Bearer Web Developer
        }
    }
    React.useEffect(() => {
        if (appState.login.loggedin) {
            fetchPricingData();
        }
    }, [appState, appState.login.loggedin]);

    // added by Bearer Web Developer end
    //  modified by Bearer Web Developer start
    return (
        <>
            <LoginPopup open={logInPopupOpen} handleClose={handleLoginInPopupClose} />
            <div className={styles.root}>
                <div className={styles.header}>
                    <h2>Mode of Transport</h2>
                    <Button variant="text" disableRipple={true} className={styles.clear} onClick={() => selectedOrder.selectOption(null)}>Clear</Button>
                </div>
                <div className={styles.transports}>
                    {transports.map(transport => <TransportEditor key={transport.type} transport={transport} selectedOrder={selectedOrder} />)}
                </div>
                <div>
                    <Button
                        className={styles.confirm}
                        onClick={handleConfirm}
                        disabled={loading}
                        disableRipple={true}
                    >
                        Confirm
                    </Button>
                    {loading && <DisabledClickLoading />}
                </div>

            </div>
        </>
    );
    //  modified by Bearer Web Developer end
}
export default TransportsEditor;

const TransportEditor: React.FC<{ transport: TTransport, selectedOrder: IOrderState }> = observer(({ transport, selectedOrder }) => {

    const handleTransportTypeSelected = () => {
        selectedOrder.selectOption(transport.type);
    }

    const selected = selectedOrder.transportOptions.selectedOption === transport.type;
    const disabled = transport.option === null
    //  modified by Bearer Web Developer start
    return (
        <>
            {disabled &&
                <div className={`${styles.transport} ${styles.transport_disabled}`}>
                    <div>
                        <TransportTypeImage type={transport.type} />
                    </div>
                    <div className={styles.price}>{transport.option?.price ? ("$ " + transport.option?.price?.toString()?.split('~')[0]) : ''}</div>
                    {
                        transport.option?.price?.toString()?.split('~')[1] &&
                        <>
                            <div className={styles.price}>~</div>
                            <div className={styles.price}>
                                {transport.option?.price ? ("$ " + transport.option?.price?.toString()?.split('~')[1]) : ''}
                            </div>
                        </>
                    }
                    <div className={styles.time}>{transport.option?.time}</div>
                </div>
            }
            {!disabled &&
                <div className={`${styles.transport} ${styles.transport_default} ${selected ? styles.transport_selected : ''}`} onClick={handleTransportTypeSelected}>
                    <div>
                        <TransportTypeImage type={transport.type} />
                    </div>
                    <div className={styles.price}>{transport.option?.price ? ("$ " + transport.option?.price?.toString()?.split('~')[0]) : ''}</div>
                    {
                        transport.option?.price?.toString()?.split('~')[1] &&
                        <>
                            <div className={styles.price}>~</div>
                            <div className={styles.price}>
                                {transport.option?.price ? ("$ " + transport.option?.price?.toString()?.split('~')[1]) : ''}
                            </div>
                        </>
                    }
                    <div className={styles.time}>{transport.option?.time}</div>
                </div>
            }
        </>
    )
    //  modified by Bearer Web Developer end
});

