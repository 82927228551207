/* this file added by Bearer Web Developer  */
import React from 'react';
import styles from './price.module.scss';
import { useAppState } from '../../../../../../../../states/app-state';


const Price: React.FC = () => {

    const appState = useAppState();
    const activeManageOrder = appState?.ReservedOrders?.activeManageOrder;
    return (
        <div className={styles.price}>
            <h3>A$ {activeManageOrder?.transport_original_price}</h3>
            <div className={styles.data_number}>
                <span>BRN: </span>
                <img src="/help-grey.webp" alt="" width={18} height={18} />
                <span>{activeManageOrder?.order_number}</span>
            </div>
        </div>
    )
}

export default Price;