// this file added by Bearer Web Developer (ReservedOrdersState)


import { flow, getEnv, Instance, types, getParentOfType, cast } from "mobx-state-tree";
import { AppState, EnvType } from "../app-state";
import { error, log } from "../../app/log";
import reservedOrdersList from "./reservedOrdersList";
import { TReservedOrdersListResponse } from "./models/reservedOrdersList";
import { TReservedOrderDetails } from "./models/reservedOrderDetails";
import { TSortBasis } from "./models/sortBasis";
import { TRemoveOrderApiIntegrationResponse } from "./models/removeOrderApiIntegration";
import removeOrderApiIntegration from "./removeOrderApiIntegration";
import { initialEditOrderApiIntegrationData, TEditOrderApiIntegrationData, TEditOrderApiIntegrationDataValue, TEditOrderApiIntegrationResponse } from "./models/editOrderApiIntegration";
import editOrderApiIntegration from "./editOrderApiIntegration";
import { fetchDownloadUrl } from "../../api/bearer-api";
import setReservedOrder from "./setReservedOrder";
export const ReservedOrdersState = types
    .model("ReservedOrdersState", {
        reservedOrdersList: types.maybeNull(types.array(types.frozen<TReservedOrderDetails>())),
        activeManageOrder: types.maybeNull(types.frozen<TReservedOrderDetails>()),
        sortBasis: types.maybeNull(types.frozen<TSortBasis>()),
        reservedOrdersOpen: types.optional(types.boolean, false),
        editOrderApiIntegrationData: types.optional(types.frozen<TEditOrderApiIntegrationData>(), initialEditOrderApiIntegrationData),
    })
    .views(self => ({
        // get activeManageOrderState() {
        //     return self.activeManageOrder;
        // },
    })).actions(self => {
        return {
            setReservedOrdersOpen(value: boolean) {
                self.reservedOrdersOpen = value;
            },
            setActiveManageOrder(value: TReservedOrderDetails | null) {
                self.activeManageOrder = value;
            },
            setSortBasis(value: TSortBasis | null) {
                self.sortBasis = value;
            },
            setEditOrderApiIntegrationData(value: TEditOrderApiIntegrationDataValue) {
                self.editOrderApiIntegrationData = { ...initialEditOrderApiIntegrationData, ...self.editOrderApiIntegrationData, ...value };
            },
            sortReservedOrdersList(sortBasis: TSortBasis) {
                const sortedData = self.reservedOrdersList?.map(r => r).sort(function compareNumbers(a, b) {
                    if (sortBasis === 'expiry_time') {
                        return (Number(a?.reservation_expiry_time) - Number(b.reservation_expiry_time));
                    } else {
                        return (Number(a?.transport_creation_time) - Number(b?.transport_creation_time));
                    }
                })
                self.reservedOrdersList = cast(sortedData);
            },
        }


    }).actions(self => {
        return {
            fetchReservedOrdersList: flow(function* () {
                const api = getEnv<EnvType>(self).api;

                ///// To solve AppCheck - WebApp Issue on Loading App State and Tabs if any
                api.callApiWithAppCheck();

                try {
                    const result: TReservedOrdersListResponse = yield reservedOrdersList();
                    if (result.code === 200) {
                        const transports: TReservedOrderDetails[] = Object.keys(result?.transports).map(id => result?.transports[id]);
                        console.log({ reservedOrdersList: transports });
                        self.reservedOrdersList = cast(transports);
                        self.sortReservedOrdersList("creation_time");

                    } else if (result.code === 499 || result.code === 401 || result.code === 406 || result.code === 408 || result.code === 400 || result.code === 500) {
                        log(result.message || 'unknown error!'); // modify by Bearer Web Developer
                    }
                } catch (err: any) {
                    error(err); // modify by Bearer Web Developer
                }
            }),
            removeOrderApiIntegration: flow(function* (transport_id: string) {
                const api = getEnv<EnvType>(self).api;

                ///// To solve AppCheck - WebApp Issue on Loading App State and Tabs if any
                api.callApiWithAppCheck();

                try {
                    const result: TRemoveOrderApiIntegrationResponse = yield removeOrderApiIntegration(transport_id);
                    if (result.code === 200) {
                        // Update the Reserved orders List Call reservedOrdersListApiIntegration
                        self.setActiveManageOrder(null);
                    } else if (result.code === 401 || result.code === 499) {
                        // Show First View (Map) in Guest Mode (logout and close all popups)
                        self.reservedOrdersOpen = false;
                        const appState = getParentOfType(self, AppState);
                        appState.login.logout();
                    } else if (result.code === 402 || result.code === 410 || result.code === 421 || result.code === 422) {
                        // (Show message first) Show Reserved Orders View after OK is clicked Call reservedOrdersListApiIntegration
                        const appState = getParentOfType(self, AppState);
                        appState.setSnackMessage({
                            message: result.message || 'unknown error!',
                            handleClick: () => {
                                // Call reservedOrdersListApiIntegration
                                self.setActiveManageOrder(null);
                            }
                        });
                    } else if (result.code === 500) {
                        // User Log out (First Action), Left Button (OK -> Reload App)
                        // Right Button (Support -> W.S.URL)
                        const appState = getParentOfType(self, AppState);
                        appState.login.logout().then(() => {
                            appState.setSnackMessage({
                                message: result?.message || 'unknown error!',
                                hasSopportLink: true,
                                handleClick: () => {
                                    window.location.reload();
                                }
                            })
                        }).catch(er => console.error(er));
                    }
                } catch (err: any) {
                    error(err); // modify by Bearer Web Developer
                }
            }),
            editOrderApiIntegration: flow(function* (editOrderApiIntegrationData: TEditOrderApiIntegrationData) {
                const api = getEnv<EnvType>(self).api;

                ///// To solve AppCheck - WebApp Issue on Loading App State and Tabs if any
                api.callApiWithAppCheck();

                try {
                    const result: TEditOrderApiIntegrationResponse = yield editOrderApiIntegration(editOrderApiIntegrationData);

                    if (result.code === 200) {
                        // Update App view (Side Bar) with all details for this reserved order, Nothing
                        // in Side bar could not be Cleared / Edited / Removed / Replaced. The only
                        // Actions available and Allowed:
                        // 1. Select another Tab
                        // 2. Open a New Tab
                        // 3. View each section for the order (VIEW ONLY)
                        // 4. Apply Discount Code
                        // 5. Pay for the Order With/Without Discount Code
                        console.log({ editOrderApiIntegrationresult: result });
                        
                        // const parceltypes: ParcelType[] = yield api.fetchParcelTypes();
                        // console.log({ parceltypes });
                        const reservedOrder = {
                            ...(self.activeManageOrder as TReservedOrderDetails),
                            ...self.editOrderApiIntegrationData
                        }
                        console.log({ reservedOrder })
                        const appState = getParentOfType(self, AppState) as any;
                        const login = appState.login.loggedin;
                        if ((login) || (!login && appState.orders.length <= 0)) {
                            yield (appState)?.addOrder();
                            appState.selectOrder(appState.orders.length - 1);
                            const parcel_img_url = yield fetchDownloadUrl(reservedOrder?.parcel_img_url);
                            setReservedOrder(appState, { ...reservedOrder, parcel_img_url });
                        }
                        self.setReservedOrdersOpen(false);
                        //const order = extractReservedOrder(reservedOrder,parceltypes);

                        // console.log({ editOrderApiIntegrationresult: result });
                    } else if (result.code === 401 || result.code === 499) {
                        // Show First View (Map) in Guest Mode (logout and close all popups)
                        const appState = getParentOfType(self, AppState);
                        appState.login.logout();
                    } else if (result.code === 402 || result.code === 410 || result.code === 421 || result.code === 422) {
                        // (Show message first) Show  Orders View after OK is clicked Call reservedOrdersListApiIntegration
                        const appState = getParentOfType(self, AppState);
                        appState.setSnackMessage({
                            message: result.message || 'unknown error!',
                            handleClick: () => {
                                // Call reservedOrdersListApiIntegration
                                self.setActiveManageOrder(null);
                            }
                        });
                    } else if (result.code === 400) {
                        // show message, 400 added by myself (it is not in the logic that has been sent)
                        const appState = getParentOfType(self, AppState);
                        appState.setSnackMessage({
                            message: result?.message || 'unknown error!',
                        });
                    } else if (result.code === 500) {
                        // User Log out (First Action), Left Button (OK -> Reload App)
                        // Right Button (Support -> W.S.URL)
                        const appState = getParentOfType(self, AppState);
                        appState.login.logout().then(() => {
                            appState.setSnackMessage({
                                message: result?.message || 'unknown error!',
                                hasSopportLink: true,
                                handleClick: () => {
                                    window.location.reload();
                                }
                            })
                        }).catch(er => console.error(er));
                    }
                } catch (err: any) {
                    error(err); // modify by Bearer Web Developer
                }
            }),
        }
    })
export interface IReservedOrdersState extends Instance<typeof ReservedOrdersState> { };

