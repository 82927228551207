/* this file added by Bearer Web Developer  */
import { Button, Dialog } from "@material-ui/core";
import React from "react";
import styles from './deleteConfirm.module.scss';
import { useAppState } from "../../../../../../../../states/app-state";


interface IDeleteConfirmProps { open: boolean, handleClose: () => void, handleRemove: (id: string) => Promise<void> }
const DeleteConfirm: React.FC<IDeleteConfirmProps> = ({ open, handleClose, handleRemove }) => {
   const appState = useAppState();
   const transport_id = appState?.ReservedOrders.activeManageOrder?.transport_id || '';
   const activeManageOrder = appState?.ReservedOrders.activeManageOrder;
   const handleDelete = () => {
      handleClose();
      handleRemove(transport_id);
   }
   return (
      <Dialog open={open} onClose={handleClose} PaperProps={{ style: { borderRadius: "10px" } }}>
         <div className={styles.root}>
            <div className={styles.header}>
               <div>
                  <h2>Remove Order {activeManageOrder?.client_order_number}</h2>
               </div>
               <Button className={styles.cancel} disableRipple={true} onClick={handleClose}>
                  <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28" fill="none">
                     <path d="M23.3334 15.1673L9.12925 15.1673L15.6626 21.7007L14.0001 23.334L4.66675 14.0007L14.0001 4.66732L15.6626 6.30065L9.12925 12.834L23.3334 12.834L23.3334 15.1673Z" fill="#858585" />
                  </svg>
               </Button>
            </div>
            <p>
               Do you want to remove the reserved order from your list?<br />
               (After removal you will not able to retrieve the details)
            </p>
            <div className={styles.confirm}>
               <Button onClick={handleClose} disableRipple={true} className={styles.no}><span>No</span></Button>
               <Button onClick={handleDelete} disableRipple={true} className={styles.yes}><span>Yes</span></Button>
            </div>
         </div>
      </Dialog>
   )
};
export default DeleteConfirm;

