// this file added by Bearer Web Developer
import { decodePolyline } from "../../../api/bearer-api";
import { IAppState, saveSelectedTransportId, TransportTypeEnum } from "../../app-state";
import { ApprovalMethodEnum } from "../models/editOrderApiIntegration";
import { TReservedOrderDetails } from "../models/reservedOrderDetails";

const setReservedOrder = (appState: IAppState, reservedOrder: TReservedOrderDetails) => {
    const reservedAlready = appState.orders.find((order) => (
        order?.selectedReservedOrder && order?.selectedReservedOrder?.transport_id === reservedOrder?.transport_id
    ));
    if (reservedAlready) {
        const reservedAlreadyIindex = appState.orders.findIndex((order) => (
            order?.selectedReservedOrder && order?.selectedReservedOrder?.transport_id === reservedOrder?.transport_id
        ));
        appState?.selectOrder(reservedAlreadyIindex);
    } else {
        appState?.selectedOrder?.setSelectedReservedOrder(reservedOrder);
        // appState?.selectedOrder?.searchRider?.clear();
        appState?.selectedOrder?.setPriceListId(reservedOrder?.price_list_id);
        // set OriginState start
        appState?.selectedOrder?.origin.setAddress({
            location: { lat: reservedOrder.origin_lat, lng: reservedOrder.origin_lng, },
            description: reservedOrder.origin_address,
        });
        appState?.selectedOrder?.origin.setPhone(reservedOrder.sender_phone_number);
        appState?.selectedOrder?.origin.setName(reservedOrder.sender_name);
        appState?.selectedOrder?.origin.setMoreDetails(reservedOrder.origin_details);
        appState?.selectedOrder?.origin.setEditing(false);
        // set OriginState end
        // set DestinationState start
        appState?.selectedOrder?.destination.setAddress({
            location: { lat: reservedOrder.destination_lat, lng: reservedOrder.destination_lng, },
            description: reservedOrder.destination_address,
        });
        appState?.selectedOrder?.destination.setPhone(reservedOrder.recipient_phone_number);
        appState?.selectedOrder?.destination.setName(reservedOrder.recipient_name);
        appState?.selectedOrder?.destination.setMoreDetails(reservedOrder.destination_details);
        appState?.selectedOrder?.destination.setApprovalMethods(reservedOrder.dropoff_verification_method as ApprovalMethodEnum);
        appState?.selectedOrder?.destination.setEditing(false);
        // set DestinationState end
        // set ParcelState start
        // console.log({ parceltypes: parceltypes.map(p => p) })
        // appState?.selectedOrder?.parcelType?.selectType(
        //     parceltypes.find(t => t.parcel_type === reservedOrder.parcel_type) as ParcelType
        // );
        appState?.selectedOrder?.parcelType?.selectType({
            id: '',// ???
            parcel_description: reservedOrder.parcel_description,
            parcel_img_url: reservedOrder.parcel_img_url,
            parcel_max_weight: reservedOrder.parcel_max_weight,
            parcel_min_weight: reservedOrder.parcel_min_weight,
            vehicle_type: {
                walking: reservedOrder.parcel_type === TransportTypeEnum.walking,
                riding: reservedOrder.parcel_type === TransportTypeEnum.riding,
                cycling: reservedOrder.parcel_type === TransportTypeEnum.cycling,
            },
            parcel_type: reservedOrder.parcel_type,
            // is_shown_for_orders: true,
            // list_index_on_the_apps: 0
        });
        appState?.selectedOrder?.parcelType?.setEditing(false);
        // set ParcelState end
        // set transport start
        appState?.selectedOrder?.transport.setValue(reservedOrder);
        saveSelectedTransportId(reservedOrder.transport_id);
        appState?.selectedOrder?.transport?.setEditing(false);
        // set transport end
        // set TransportOption start
        appState?.selectedOrder?.setTransportOption({
            price: reservedOrder?.transport_original_price,
            overview_polyline: reservedOrder?.overview_polyline,
            overview_polyline_latlng: decodePolyline(reservedOrder?.overview_polyline),
            time: reservedOrder?.transport_time,
            destination_address: reservedOrder?.destination_address,
            length: reservedOrder?.transport_length,
            origin_address: reservedOrder?.origin_address,
            duration: reservedOrder?.transport_duration,
            type: reservedOrder?.bearer_type as TransportTypeEnum,
            distance: 0, // ???
        });
        appState?.selectedOrder?.selectOption(reservedOrder?.bearer_type as TransportTypeEnum);
        // set TransportOption end
    }
}

export default setReservedOrder;