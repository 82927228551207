// this file added by Bearer Web Developer
export type TApiIntegrationData = {
    api_key: String,
    api_secret: String,
    allowed_intermediate_api_providers: string[], // ['Bearer'] {/* data cannot be deleted */}
    intermediate_api_providers: string[], // ['Bearer'] {/* data can be deleted */}
    allowed_intermediate_api_platfroms: string[], // ['String'] {/* data cannot be deleted */}
    intermediate_api_platfroms: string[], // ['String'] {/* data can be deleted */}
}
export type TApiIntegrationDataValue = {
    api_key?: String,
    api_secret?: String,
    allowed_intermediate_api_providers?: string[], // ['Bearer'] {/* data cannot be deleted */}
    intermediate_api_providers?: string[], // ['Bearer'] {/* data can be deleted */}
    allowed_intermediate_api_platfroms?: string[], // ['String'] {/* data cannot be deleted */}
    intermediate_api_platfroms?: string[], // ['String'] {/* data can be deleted */}
}
export const initialApiIntegrationData = {
    api_key: '',
    api_secret: '',
    allowed_intermediate_api_providers: [], // ['Bearer'] {/* data cannot be deleted */}
    intermediate_api_providers: [], // ['Bearer'] {/* data can be deleted */}
    allowed_intermediate_api_platfroms: [], // ['String'] {/* data cannot be deleted */}
    intermediate_api_platfroms: [], // ['String'] {/* data can be deleted */}
}