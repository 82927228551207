import React from "react";
import { Button, Dialog, Link } from "@material-ui/core";
import BearerTextField from "../../components/fields/BearerTextField";
import ResetPasswordPopup from "./ResetPasswordPopup";
import { Observer } from "mobx-react-lite";
import { useAppState } from "../../states/app-state";
import DisabledClickLoading from "../../components/DisabledClickLoading";
import { log } from "../../app/log"; // added by Bearer Web Developer
import styles from './LoginPopup.module.scss'; // added by Bearer Web Developer
import BearertPasswordwithForget from "../../components/fields/BearertPasswordwithForget"; // added by Bearer Web Developer
import { analytics } from "../../api/bearer-api"; // added by Bearer Web Developer
import { logEvent } from "firebase/analytics"; // added by Bearer Web Developer
import ReactPixel from 'react-facebook-pixel'; // added by Amir Siabi 26 Aug 2024
// useStyles removed by Bearer Web Developer

interface ILoginPopupProps { open: boolean, handleClose: () => void }
const LoginPopup: React.FC<ILoginPopupProps> = ({ open, handleClose }) => {

    const appState = useAppState();
    const login = appState.login;
    const businessCustomerOnboardingUrl = appState?.webAppRemoteConfigParameters?.businessCustomerOnboardingUrl || ''; // added by Bearer Web Developer
    const [error, setError] = React.useState('');
    const [loading, setLoading] = React.useState(false);
    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        const valid = !!login.email && !!login.password && !!login.email.trim();
        if (valid) {
            try {
                setLoading(true);
                setError('');
                await appState.login.login();
                setLoading(false)
                handleClose();
            } catch (err: any) {
                setLoading(false);
                // console.log({LoginPopupError: err?.message}) // added by Bearer Web Developer
                // the setError modified by Bearer Web Developer start
                if (err?.message === "Firebase: Error (auth/wrong-password).")
                    setError("Incorrect password");
                else if (err?.message === "Firebase: Error (auth/user-not-found).")
                    setError("User doesn’t exist");
                else if (err?.message === "Firebase: Error (auth/user-disabled).")
                    setError("Account disabled");
                else if (err?.message === "Firebase: Error (auth/network-request-failed.")
                    setError("We are experiencing network issues, please check your internet connection and try again.");
                else if (err?.message === "Firebase: Access to this account has been temporarily disabled due to many failed login attempts. You can immediately restore it by resetting your password or you can try again later. (auth/too-many-requests).")
                    setError("Access to this account has been temporarily disabled due to many failed login attempts. You can immediately restore it by resetting your password or you can try again later.");
                else {
                    log(err?.message);
                }
                // the setError modified by Bearer Web Developer end
            }
        }
    }

    const [resetPasswordOpen, setResetPasswordOpen] = React.useState(false);
    const handleResetPassword = () => {
        setResetPasswordOpen(true);
    }
    const handleResetPasswordClose = () => {
        setResetPasswordOpen(false);
    }
    // commented by Bearer Web Developer start
    // const [showPassword, setShowPassword] = React.useState(false);

    // const handleClickShowPassword = () => {
    //     setShowPassword(!showPassword);
    // };

    // const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    //     event.preventDefault();
    // };
    // commented by Bearer Web Developer end

    // const handleKeyPress = (e: React.KeyboardEvent) => {
    //     if (e.key === 'Enter' && !e.shiftKey) {
    //         e.preventDefault();
    //         handleConfirmLogin();
    //     }
    // }
    // added by Bearer Web Developer start
    const handleCreateAccount = () => {
        // added by Bearer Web Developer start
        // Firebase Analytics Event Name Creat New Account Clicked
        // App Event On Log In page Create Account Button clicked
        logEvent(analytics,"business_sign_up_started",{
            sign_up_source: "Bearer WebApp Login"
        });
        if (appState?.environmentCode === "PRODUCTION") {
        // added by Amir Siabi 26 Aug 2024
        ReactPixel.trackSingle('3587653761499122', 'BusinessSignUpStarted'); // For tracking custom events.
        // added by Amir Siabi 26 Aug 2024
        }
        // added by Bearer Web Developer end
    }
    // added by Bearer Web Developer end
    // modified by Bearer Web Developer start
    return (
        <>
            <ResetPasswordPopup open={resetPasswordOpen} handleClose={handleResetPasswordClose} />
            <Dialog
                open={open}
                onClose={handleClose} PaperProps={{ style: { borderRadius: "10px" } }}
            >
                <div className={styles.root}>
                    <div className={styles.header}>
                        <h2>Welcome to Bearer</h2>
                        <Button className={styles.cancel} disableRipple={true} onClick={handleClose}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28" fill="none">
                                <path d="M9.79992 19.833L13.9999 15.633L18.1999 19.833L19.8333 18.1997L15.6333 13.9997L19.8333 9.79967L18.1999 8.16634L13.9999 12.3663L9.79992 8.16634L8.16659 9.79967L12.3666 13.9997L8.16659 18.1997L9.79992 19.833ZM13.9999 25.6663C12.386 25.6663 10.8694 25.3601 9.44992 24.7476C8.03047 24.1351 6.79575 23.3038 5.74575 22.2538C4.69575 21.2038 3.8645 19.9691 3.252 18.5497C2.6395 17.1302 2.33325 15.6136 2.33325 13.9997C2.33325 12.3858 2.6395 10.8691 3.252 9.44967C3.8645 8.03023 4.69575 6.79551 5.74575 5.74551C6.79575 4.69551 8.03047 3.86426 9.44992 3.25176C10.8694 2.63926 12.386 2.33301 13.9999 2.33301C15.6138 2.33301 17.1305 2.63926 18.5499 3.25176C19.9694 3.86426 21.2041 4.69551 22.2541 5.74551C23.3041 6.79551 24.1353 8.03023 24.7478 9.44967C25.3603 10.8691 25.6666 12.3858 25.6666 13.9997C25.6666 15.6136 25.3603 17.1302 24.7478 18.5497C24.1353 19.9691 23.3041 21.2038 22.2541 22.2538C21.2041 23.3038 19.9694 24.1351 18.5499 24.7476C17.1305 25.3601 15.6138 25.6663 13.9999 25.6663ZM13.9999 23.333C16.6055 23.333 18.8124 22.4288 20.6208 20.6205C22.4291 18.8122 23.3333 16.6052 23.3333 13.9997C23.3333 11.3941 22.4291 9.18717 20.6208 7.37884C18.8124 5.57051 16.6055 4.66634 13.9999 4.66634C11.3944 4.66634 9.18742 5.57051 7.37909 7.37884C5.57075 9.18717 4.66659 11.3941 4.66659 13.9997C4.66659 16.6052 5.57075 18.8122 7.37909 20.6205C9.18742 22.4288 11.3944 23.333 13.9999 23.333Z" fill="#858585" />
                            </svg>
                        </Button>
                    </div>
                    <p>Enter your details to log in to your account.</p>
                    <form onSubmit={handleSubmit}>
                        <div className={''}>
                            <Observer>{() => (
                                <div className={styles.email_input}>
                                    <BearerTextField
                                        colorStyle="gray"
                                        label="Email address"
                                        placeholder="email@example.com"
                                        type="email"
                                        name="email"
                                        autoComplete="email"
                                        value={login.email}
                                        onChange={(e) => login.setEmail(e.target.value)}
                                        clear={()=>{ login.setEmail('') }} // added by Bearer Web Developer
                                        validator={validateEmail}
                                    />
                                </div>
                            )}</Observer>
                            <Observer>{() => (
                                // commented by Bearer Web Developer start
                                // <FormControl>
                                //     <InputLabel >Password</InputLabel>
                                //     <FilledInput
                                //         value={login.password}
                                //         type={showPassword ? 'text' : 'password'}
                                //         name="current-password"
                                //         autoComplete="current-password"
                                //         onChange={(e) => login.setPassword(e.target.value)}
                                //         // onKeyPress={handleKeyPress}
                                //         endAdornment={
                                //             <InputAdornment position="end">
                                //                 <IconButton
                                //                     onClick={handleClickShowPassword}
                                //                     onMouseDown={handleMouseDownPassword}
                                //                 >
                                //                     {showPassword ? <Visibility /> : <VisibilityOff />}
                                //                 </IconButton>
                                //             </InputAdornment>
                                //         }

                                //     />
                                //     {error && <FormHelperText error={!!error}  >{error}</FormHelperText>}
                                // </FormControl>
                                // commented by Bearer Web Developer end
                                // added by Bearer Web Developer start
                                <div className={styles.password_input}>
                                    <BearertPasswordwithForget
                                        value={login.password}
                                        name="current-password"
                                        label="Password"
                                        placeholder="Password"
                                        autoComplete="current-password"
                                        handleResetPassword={handleResetPassword}
                                        onChange={(e) => login.setPassword(e.target.value)}
                                        // onKeyPress={handleKeyPress}
                                        errorField={error}
                                        colorStyle="gray"
                                    />
                                </div>
                                // added by Bearer Web Developer end
                            )}</Observer>
                            <Observer>{() => {
                                const valid = !!login.email && !!login.password && !!login.email.trim();
                                return (
                                    <>
                                        <Button
                                            type='submit'
                                            className={styles.login}
                                            variant="text"
                                            color="primary"
                                            fullWidth
                                            disabled={!valid}
                                            disableRipple={true}
                                        >
                                            Login
                                        </Button>
                                        {loading && <DisabledClickLoading />}
                                    </>
                                )
                            }}</Observer>
                            {/*removed text and link by Bearer Web Developer */}
                            {/*added by Bearer Web Developer start */}
                            <div className={styles.or}>
                                <span className={styles.left_line}></span>
                                <span className={styles.or_text}>OR</span>
                                <span className={styles.right_line}></span>
                            </div>
                            <div className={styles.signup}>
                                <Link href={businessCustomerOnboardingUrl} onClick={handleCreateAccount} rel="noreferre">Create Account</Link>
                            </div>
                            {/*added by Bearer Web Developer end */}
                        </div>

                    </form>
                </div>
            </Dialog>
        </>
    );
    // modified by Bearer Web Developer end
}
export default LoginPopup;


function validateEmail(value: string) {
    if (!(value.match(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/))) return 'Email address is not valid!'
}

