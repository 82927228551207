/* this file added by Bearer Web Developer  */
import ExpireTime from './expireTime';
import Locations from './locations';
import MapBox from './mapBox';
import styles from './orderData.module.scss';
import Price from './price';
import Time from './time';

const OrderData: React.FC = () => {
    return (
        <div className={styles.order_data}>
            <Price />
            <Time />
            <ExpireTime />
            <MapBox />
            <Locations />
        </div>
    )
}
export default OrderData;