/* this file added by Bearer Web Developer  */
import { Timeline, TimelineConnector, TimelineContent, TimelineItem, TimelineSeparator } from '@material-ui/lab';
import styles from './locations.module.scss';
import { useContext } from 'react';
import { ReservedOrderInfoContext } from '../../contexts/reservedOrderInfo';

const Locations: React.FC = () => {
    const reservedOrderDetails = useContext(ReservedOrderInfoContext);
    return (
        <div className={styles.locations}>
            <Timeline classes={{ root: styles.time_line }}>
                <TimelineItem classes={{ missingOppositeContent: styles.missing_opposite_content }}>
                    <TimelineSeparator>
                        <img src="/origin.png" alt="origin" />
                        <TimelineConnector className={''} />
                    </TimelineSeparator>
                    <TimelineContent classes={{ root: styles.timeline_content_root }}>
                        <div className={styles.timeline_content}>
                            <p>{reservedOrderDetails?.origin_address}</p>
                            <span>{reservedOrderDetails?.origin_details}</span>
                        </div>
                    </TimelineContent>
                </TimelineItem>
                <TimelineItem classes={{ missingOppositeContent: styles.missing_opposite_content, root: styles.timelineItem_root }}>
                    <TimelineSeparator >
                        <img src="/destination.png" alt="destination" />
                    </TimelineSeparator>
                    <TimelineContent classes={{ root: styles.timeline_content_root }}>
                        <div className={styles.timeline_content}>
                            <p>{reservedOrderDetails?.destination_address}</p>
                            <span>{reservedOrderDetails?.destination_details}</span>
                        </div>
                    </TimelineContent>
                </TimelineItem>
            </Timeline>
        </div>
    )
}
export default Locations;