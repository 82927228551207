/* this file added by Bearer Web Developer  */
import { Button, Menu, MenuItem } from "@material-ui/core";
import React from "react";
import styles from './sortMenu.module.scss';
import { useAppState } from "../../../states/app-state";
import { TSortBasis } from "../../../states/reservedOrdersState/models/sortBasis";


const SortMenu: React.FC = () => {
    const appState = useAppState();
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };
    const sortBy = (sortBasis: TSortBasis) => {
        appState?.ReservedOrders?.sortReservedOrdersList(sortBasis);
        appState?.ReservedOrders?.setSortBasis(sortBasis);
        handleClose();
    };
    return (
        <div className={styles.sort_menu}>
            <Button className={styles.filter} disableRipple={true} onClick={handleClick}>
                <img src="/filter-black.webp" alt="" width={24} height={24} />
                <span>Sort By</span>
            </Button>
            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
            >
                <MenuItem onClick={() => { sortBy('expiry_time') }}>Expiry Time</MenuItem>
                <MenuItem onClick={() => { sortBy('creation_time') }}>Creation Time</MenuItem>
            </Menu>
        </div>
    )
}
export default SortMenu;

