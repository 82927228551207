import React, { useEffect } from 'react';
import { CssBaseline, MuiThemeProvider } from '@material-ui/core';
import createTheme from './theme';
import { AppStateProvider } from './states/app-state';
import Scaffold from './containers/Scaffold';
import setHotjar from './tags/hotjar';
import metaPixelCode from './tags/metaPixelCode';
import googleTagManager from './tags/googleTagManager';
import { BearerApi, getRemoteConfigData } from './api/bearer-api';

// added by Bearer Web Developer start
const getinitialData = async () => {

  BearerApi.callApiWithAppCheck();
  const result = await getRemoteConfigData();
  if (result?.environmentCode === "PRODUCTION") {
    setHotjar();
    metaPixelCode();
    googleTagManager();
  }
}

function App() {
  useEffect(() => {
   getinitialData();

  }, []);
// added by Bearer Web Developer end
  return (
    <MuiThemeProvider theme={createTheme}>
      <CssBaseline />
      <AppStateProvider>
        <Scaffold />
      </AppStateProvider>
    </MuiThemeProvider>
  );
}

export default App;
