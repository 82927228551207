/* this file added by Bearer Web Developer  */
import { Button } from "@material-ui/core";
import React from "react";
import DeleteConfirm from "./deleteConfirm";
import styles from './delete.module.scss';
import { useAppState } from "../../../../../../../states/app-state";
import DisabledClickLoading from "../../../../../../../components/DisabledClickLoading";
const Delete: React.FC = () => {

    const [deleteConfirmOpen, setDeleteConfirmOpen] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const openDeleteConfirm = () => {
        setDeleteConfirmOpen(true)
    }
    const handleClose = () => {
        setDeleteConfirmOpen(false)
    }
    const appState = useAppState();
    const handleRemove = async (transport_id: string) => {
        setLoading(true);
        await appState?.ReservedOrders?.removeOrderApiIntegration(transport_id);
        setLoading(false);
        appState?.ReservedOrders.setActiveManageOrder(null);
    }
    return (
        <div className={styles.delete_part}>
            <Button className={`${styles.delete_button} ${loading ? styles.loading : ''}`} onClick={openDeleteConfirm}>
                <img src="/delete.png" width={30} height={30} alt="delete" />
            </Button>
            {deleteConfirmOpen && !loading &&
                <DeleteConfirm handleRemove={handleRemove} open={deleteConfirmOpen} handleClose={handleClose} />}
            {loading && <DisabledClickLoading />}
        </div>
    )
}
export default Delete;