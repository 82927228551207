/* this file added by Bearer Web Developer  */
import { useAppState } from '../../../../../../../states/app-state';
import styles from './modeOfTransport.module.scss';
enum vehicle_type {
    walking = "walking",
    riding = "riding",
    cycling = "cycling"
}
const ModeOfTransport: React.FC = () => {
    const appState = useAppState();
    const bearer_type = appState?.ReservedOrders.activeManageOrder?.bearer_type;
    const activeBicycle = bearer_type === vehicle_type.walking || bearer_type === vehicle_type.cycling;
    const activeRun = bearer_type === vehicle_type.walking;
    return (
        <div className={styles.mode_of_transport}>
            <h4>Mode(s) of Transport:</h4>
            <div className={styles.modes}>
                <div className={`${styles.mode_type} ${styles.active_mode_type}`}>
                    <img src="motorcycle.png" alt="" />
                </div>
                <div className={`${styles.mode_type} ${activeBicycle ? styles.active_mode_type : ''}`}>
                    <img src="pedal_bike_transport_info.png" alt="" />
                </div>
                <div className={`${styles.mode_type} ${activeRun ? styles.active_mode_type : ''}`}>
                    <img src="directions_run_transport_info.png" alt="" />
                </div>
            </div>
        </div>
    )
}
export default ModeOfTransport;