/* this file added by Bearer Web Developer  */
import React, { useContext } from 'react';
import styles from './price.module.scss';
import { ReservedOrderInfoContext } from '../../contexts/reservedOrderInfo';

const Price: React.FC = () => {

    const reservedOrderDetails = useContext(ReservedOrderInfoContext);
    return (
        <div className={styles.price}>
            <h3>A$ {reservedOrderDetails?.transport_original_price}</h3>
            <div className={styles.data_number}>
                <span>BRN: </span>
                <img src="/help-grey.webp" alt="" width={18} height={18} />
                <span>{reservedOrderDetails?.order_number}</span>
            </div>
        </div>
    )
}

export default Price;