/* this file added by Bearer Web Developer  */
import { firebaseApp } from "../../../api/bearer-api";
import { error } from "../../../app/log";

import { getFunctions, httpsCallable } from 'firebase/functions';
import { TEnableApiIntegrationResponse } from "../models/enableApiIntegration";

const enableApiIntegration = async (): Promise<TEnableApiIntegrationResponse | void> => { // void type added by Bearer Web Developer
    try {
        // add the location string as you call getFunctions
        const functions = getFunctions(firebaseApp, 'australia-southeast2');
        const t = httpsCallable<any, TEnableApiIntegrationResponse>(functions, 'enableApiIntegration');
        const result = await t();
        return result.data;
    } catch (err: any) {
        error(err); // modify by Bearer Web Developer
        error(err?.message || 'unknown error');   // throw new Error modified by Bearer Web Developer 
    }
}
export default enableApiIntegration;