/* this file added by Bearer Web Developer  */
import React from 'react';
import { TTransportStateResponse } from "../../../api/bearer-api";
import styles from './showSteps.module.scss';

interface IShowSteps {
    searchResponse: TTransportStateResponse | null
}

const ShowSteps: React.FC<IShowSteps> = ({ searchResponse }) => {
    const realtime_code = searchResponse?.realtime_code;
    const step = realtime_code == 3 ? "step_one" : realtime_code == 7 ? "step_two" : realtime_code == 8 ? "step_three" :
        realtime_code == 9 ? "step_four" : "step_five";
    return (
        <div className={styles.show_steps}>
            <div className={`${styles.main_bar} ${styles[step]}`}>
                <div className={styles.active_bar}></div>
                <svg className={styles.main_cycle_one} xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 10 10" fill="none">
                    <circle cx="5" cy="5" r="5" fill="#4E92FF" />
                </svg>
                <svg className={styles.active_cycle_one} xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                    <circle cx="7" cy="7" r="7" fill="#32B1FF" />
                </svg>
                <svg className={styles.active_cycle_one_end} xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                    <circle cx="7" cy="7" r="7" fill="#FF8A29" />
                </svg>
                <svg className={styles.main_cycle_two} xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 10 10" fill="none">
                    <circle cx="5" cy="5" r="5" fill="#4E92FF" />
                </svg>
                <svg className={styles.active_cycle_two} xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                    <circle cx="7" cy="7" r="7" fill="#32B1FF" />
                </svg>
                <svg className={styles.active_cycle_two_end} xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                    <circle cx="7" cy="7" r="7" fill="#FF8A29" />
                </svg>
                <svg className={styles.main_cycle_three} xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 10 10" fill="none">
                    <circle cx="5" cy="5" r="5" fill="#4E92FF" />
                </svg>
                <svg className={styles.active_cycle_three} xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                    <circle cx="7" cy="7" r="7" fill="#32B1FF" />
                </svg>
                <svg className={styles.active_cycle_three_end} xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                    <circle cx="7" cy="7" r="7" fill="#FF8A29" />
                </svg>
                <svg className={styles.main_cycle_four} xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 10 10" fill="none">
                    <circle cx="5" cy="5" r="5" fill="#4E92FF" />
                </svg>
                <svg className={styles.active_cycle_four} xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                    <circle cx="7" cy="7" r="7" fill="#32B1FF" />
                </svg>
                <svg className={styles.active_cycle_four_end} xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                    <circle cx="7" cy="7" r="7" fill="#FF8A29" />
                </svg>
            </div>
        </div>
    )
}

export default ShowSteps;