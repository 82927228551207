/* this file added by Bearer Web Developer  */
import React from "react";
import styles from './googleReview.module.scss';
import { Link } from "@material-ui/core";
import { useAppState } from "../../../states/app-state";
import { observer } from "mobx-react-lite";

const GoogleReview: React.FC = observer(() => {

    const appState = useAppState();
    const bearerGoogleReviewUrl = appState?.webAppRemoteConfigParameters?.bearerGoogleReviewUrl;

    return (
        <Link className={styles.google_review} href={bearerGoogleReviewUrl} underline="none" target="_blank">
            <div className={styles.review_part}>
                <img src="/reviews.webp" alt="" width={28} height={28} />
            </div>
            <div className={styles.google_part}>
                <p>Leave us a review on</p>
                <img src="/google-2015-logo.webp" alt="" width={83} height={28.074} />
            </div>
        </Link>
    )
});
export default GoogleReview;