/* this file added by Bearer Web Developer  */
import { Button, Menu } from "@material-ui/core";
import React from "react";
import styles from './addMenu.module.scss';
import { useAppState } from "../../../../../../../states/app-state";
import BearerTextField from "../../../../../../fields/BearerTextField";
interface Props {
    setLoading: React.Dispatch<React.SetStateAction<boolean>>
}
const AddMenu: React.FC<Props> = ({ setLoading }) => {

    const appState = useAppState();
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const [text, setText] = React.useState<string>('');
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    function validateText(value: string) {
        if (value.length === 0) return "Cannot be left blank";
    }
    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        if (!text) return;
        setAnchorEl(null);
        const { apiIntegrationData } = appState?.ApiIntegration;
        setLoading(true);
        await appState?.ApiIntegration?.fetchUpdateProvidersApiIntegration(
            [
                ...(apiIntegrationData?.allowed_intermediate_api_providers || []),
                ...(apiIntegrationData?.intermediate_api_providers || []), text
            ]
        );
        setText('');
        setLoading(false);
    };
    const handleClose = () => {
        setAnchorEl(null);
        setText('');
    };

    return (
        <div className={styles.add_menu}>
            <Button className={styles.add_button} onClick={handleClick} type="button">
                <img src="/add-black-api.webp" alt="" width={20} height={20} />
            </Button>
            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
            >
                <div>
                    <form className={styles.add_form} onSubmit={handleSubmit}>
                        <BearerTextField
                            name='text'
                            placeholder={"..."}
                            value={text}
                            onChange={(e) => setText(e.target.value)}
                            clear={() => { setText('') }} // added by Bearer Web Developer
                            validator={validateText}
                            inputSmallStyle={true}
                            colorStyle="gray"
                            withoutLabel={true}
                        />
                        <div>
                            <Button className={`${styles.add_submit} ${!text ? styles.add_submit_disabled : ''}`}
                                type="submit">
                                <img width={20} height={20} src="/tick.webp" alt="" />
                            </Button>
                        </div>
                    </form>
                </div>
            </Menu>
        </div>
    )
}
export default AddMenu;

