import React from 'react';
import { Button } from '@material-ui/core';
import { IOriginState, useAppState } from '../../../states/app-state';
import styles from './OriginCompact.module.scss'; // added by Bearer Web Developer
import { observer } from 'mobx-react-lite'; // added by Bearer Web Developer
// useStyles removed by Bearer Web Developer

const OriginCompact: React.FC<{ origin: IOriginState }> = observer(({ origin }) => { // observer added by Bearer Web Developer
    const appState = useAppState(); // added by Bearer Web Developer
    const isDisabled = origin.isDisabled;
    const handleEdit = () => origin.setEditing(true);
    // modified by Bearer Web Developer start
    return (
        <div className={styles.root}>
            <div className={styles.main}>
                <div className={styles.header}>
                    <div className={styles.origin}>
                        <img src="/origin.png" alt="origin" />
                        <h2>Origin</h2>
                    </div>
                    <div >
                        {!isDisabled && !appState?.selectedOrder?.selectedReservedOrder && // selectedReservedOrder added by Bearer Web Developer
                            <div>
                                <Button className={styles.edit} variant="text" onClick={handleEdit}>Edit</Button>
                            </div>}
                    </div>
                </div>
                {
                    origin.address?.description &&
                    <div className={styles.description}>
                        <p>{origin.address?.description}</p>
                    </div>
                }
            </div>
        </div>
    );
    // modified by Bearer Web Developer end
})
export default OriginCompact;
