/* this file added by Bearer Web Developer  */
import React, { useState } from "react";
import { observer } from "mobx-react-lite";
import styles from './switch.module.scss';
import { useAppState } from "../../../../states/app-state";
import DisabledClickLoading from "../../../DisabledClickLoading";

const SwitchIntegration: React.FC = observer(() => {
    const appState = useAppState();
    const { setSwitchIntegration } = appState?.ApiIntegration;
    const [loading, setLoading] = useState<boolean>(false);
    console.log({ switchIntegration: appState?.ApiIntegration?.switchIntegration })
    const handleSwitchIntegration = async (checked: boolean) => {
        if (loading) return;
        if (checked) {
            // readyfor enableApiIntegration
            if (!appState?.ApiIntegration?.is_api_access_enabled_by_user) {
                setSwitchIntegration(true);
            }
        } else {
            // disableApiIntegration
            if (appState?.ApiIntegration?.is_api_access_enabled_by_user) {
                setLoading(true);
                await appState?.ApiIntegration?.fetchDisableApiIntegration();
                setLoading(false);
            }
        }
    }
    return (
        <div className={styles.switch_integration}>
            <div className={styles.enable} onClick={() => { handleSwitchIntegration(true) }}>Enable</div>
            <div className={styles.switch}>
                <label>
                    <input type="checkbox" checked={appState?.ApiIntegration?.switchIntegration}
                        onChange={(e) => { handleSwitchIntegration(e?.target?.checked) }} />
                    <span className={styles.slider}></span>
                </label>
            </div>
            <div className={styles.disable} onClick={() => { handleSwitchIntegration(false) }}>Disable</div>
            {loading && <DisabledClickLoading />}
        </div>
    )
})
export default SwitchIntegration;