import React from 'react';
import { Button } from '@material-ui/core';
import { IDestinationState, useAppState } from '../../../states/app-state';
import styles from './DestinationCompact.module.scss'; // added by Bearer Web Developer
import { observer } from 'mobx-react-lite'; // added by Bearer Web Developer
// useStyles removed by Bearer Web Developer

const DestinationCompact: React.FC<{ destination: IDestinationState }> = observer(({ destination }) => { // observer added by Bearer Web Developer
    const appState = useAppState(); // added by Bearer Web Developer
    const isDisabled = destination.isDisabled;
    const handleEdit = () => destination.setEditing(true);
    // modified by Bearer Web Developer start
    return (
        <div className={styles.root}>
            <div className={styles.main}>
                <div className={styles.header}>
                    <div className={styles.destination}>
                        <img src="/destination.png" alt="destination" />
                        <h2>Destination</h2>
                    </div>
                    <div >
                        {!isDisabled && !appState?.selectedOrder?.selectedReservedOrder && // selectedReservedOrder added by Bearer Web Developer
                            <div>
                                <Button className={styles.edit} variant="text" onClick={handleEdit}>Edit</Button>
                            </div>}
                    </div>
                </div>
                {
                    destination.address?.description &&
                    <div className={styles.description}>
                        <p>{destination.address?.description}</p>
                    </div>
                }
            </div>
        </div>
    );
    // modified by Bearer Web Developer end
})

export default DestinationCompact;
