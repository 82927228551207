/* this file added by Bearer Web Developer  */
import React from 'react';
import styles from './order.module.scss'
import Name from './name';
import Management from './managememnt';

import { ReservedOrderInfoContext } from './contexts/reservedOrderInfo';
import OrderData from './orderData';
import { TReservedOrderDetails } from '../../../../states/reservedOrdersState/models/reservedOrderDetails';
interface Props {
    reservedOrderInfo: TReservedOrderDetails
}
const Order: React.FC<Props> = ({ reservedOrderInfo }) => {

    return (
        <ReservedOrderInfoContext.Provider value={reservedOrderInfo}>
            <div className={styles.order}>
                <div className={styles.header}>
                    <img src="/confirmation-number.webp" alt="" width={20} height={20} />
                    <span>{reservedOrderInfo?.business_name || 'Seyco Invoice No.'} : {reservedOrderInfo?.client_order_number}</span>
                </div>
                <OrderData />
                <div className={styles.management_part}>
                    <Name />
                    <Management />
                </div>
            </div>
        </ReservedOrderInfoContext.Provider>
    )
}

export default Order;