/* this file added by Bearer Web Developer  */
import BearerMapWrapper from '../../../../../../../../map/bearerMapWrapper';
import Map from './map';
import styles from './mapBox.module.scss';

const MapBox: React.FC = () => {
    return (
        <div className={styles.map_box}>
            <BearerMapWrapper>
                <Map />
            </BearerMapWrapper>
        </div>
    )
}
export default MapBox;