/* this file added by Bearer Web Developer  */
import React, { useState } from 'react';
import styles from './apiRequestsOrigin.module.scss';
import { useAppState } from '../../../../../../states/app-state';
import AddMenu from './addMenu';
import { observer } from 'mobx-react-lite';
import DisabledClickLoading from '../../../../../DisabledClickLoading';
import RemoveConfirm from './../removeConfirm';

const ApiRequestsOrigin: React.FC = observer(() => {
    const appState = useAppState();
    const allowed_intermediate_api_platfroms = appState?.ApiIntegration?.apiIntegrationData?.allowed_intermediate_api_platfroms;
    const intermediate_api_platfroms = appState?.ApiIntegration?.apiIntegrationData?.intermediate_api_platfroms;
    const [loading, setLoading] = useState(false);
    const removePlatform = async (v: string) => {
        setLoading(true)
        await appState?.ApiIntegration?.fetchUpdatePlatformsApiIntegration(
            [
                ...(allowed_intermediate_api_platfroms || []), // data cannot be deleted 
                ...(intermediate_api_platfroms?.filter(p => p !== v) || []) // data can be deleted (remove item)
            ]
        );
        setLoading(false)
    }
    // RemoveConfirm start
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClickOpenRemoveConfirm = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    // RemoveConfirm end
    return (
        <div className={styles.api_requests_origin}>
            <div className={styles.header}>
                <h3>API Requests Origin</h3>
                <AddMenu setLoading={setLoading} />
            </div>
            <div className={styles.list}>
                <ul>
                    {allowed_intermediate_api_platfroms?.map((v, i) => (<li key={i}>{v}</li>))} {/* data cannot be deleted */}
                    {intermediate_api_platfroms?.map((v, i) => (
                        <li key={i}>
                            {v}
                            <span onClick={handleClickOpenRemoveConfirm} className={styles.delete}>
                                <img width={15} height={14} src="/backspace-delete.webp" alt="X" />
                            </span>
                            <RemoveConfirm anchorEl={anchorEl}
                                handleDelete={() => { removePlatform(v) }}
                                handleClose={handleClose} open={open} valueItem={v} />
                        </li>
                    ))} {/* data can be deleted */}
                </ul>
            </div>
            {loading && <DisabledClickLoading />}
        </div>
    )
})
export default ApiRequestsOrigin;