/* this file added by Bearer Web Developer  */
import React from 'react';
import { Button, TextFieldProps } from '@material-ui/core';
import styles from './BearerTextFieldPassword.module.scss'; // added by Bearer Web Developer
type IColorStyle = "blue" | "gray" | "white";
type BearerTextFieldPasswordProps = TextFieldProps & { validator?: (value: string) => string | undefined } & { colorStyle?: IColorStyle } &{errorField?: string};
// inputProperties interface added by Bearer Web Developer
interface inputProperties {
    label?: string
    value?: string
    checked?: boolean
    onChange?: () => {}
    onClick?: () => {}
    disabled?: boolean
    placeholder?: string
    name?: string
}
const BearerTextFieldPassword: React.FC<BearerTextFieldPasswordProps> = ({ validator, colorStyle,errorField,...props }) => {
    const [touched, setTouched] = React.useState(false);
    const [error, setError] = React.useState({ value: null as null | string, error: false, helperText: '' });
    const [showPassword, setShowPassword] = React.useState(false);

    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };

    const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
    };

    React.useEffect(() => {
        if (validator) {
            const value = props.value as string;
            if (error.value !== value) {
                const helperText = validator(value);
                if (helperText) {
                    setError({ value, error: true, helperText });
                } else {
                    if (error.error) {
                        setError({ value, error: false, helperText: '' });
                    }
                }
            }
        }
    }, [validator, props.value, touched, error, setError])

    const handleFocus = () => {
        setTouched(true);
    }
    // modified by Bearer Web Developer start
    const { inputProps, label, name, placeholder, ...otherProps } = props;
    return (
        <div className={styles.text_input}>
            <div className={`${colorStyle ? styles?.[colorStyle] : styles.blue}`}>
                <div className={styles.label}>
                    <label htmlFor={name || label?.toString()}>{label}</label>
                </div>
                <div className={styles.input}>
                    <input {...inputProps} name={name || label?.toString()}
                        id={name || label?.toString()} type={showPassword ? 'text' : 'password'}
                        {...otherProps as inputProperties} onFocus={handleFocus} placeholder={placeholder} />
                    <Button
                        className={styles.visibility}
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                    >
                        <img src={`/visibility-${showPassword ? "on" : "off"}.png`}
                            alt={`/visibility ${showPassword ? "on" : "off"}`}
                        />
                    </Button>
                </div>
                {
                    errorField ?<div className={styles.error}>{errorField}</div> :
                    error.error && <div className={styles.error}>{error.helperText}</div>
                }
            </div>
        </div>
    );
    // modified by Bearer Web Developer end
}
export default BearerTextFieldPassword;