// this component added by Bearer Web Developer
import React from 'react';
import { Observer } from "mobx-react-lite";
import { useAppState } from "../../../states/app-state";
import styles from "./businessName.module.scss";

const BusinessName: React.FC = () => {
    const appState = useAppState();

    return (
        <Observer>{() => (
            <>
                {
                    appState.login.loggedin ?
                        <div className={styles.business_name}>
                            <div className={styles.splitter}></div>
                            <h3>{appState.profile.businessName}</h3>
                        </div> : <div></div>
                }
            </>
        )}</Observer>
    )
}
export default BusinessName;