/* this file added by Bearer Web Developer  */
import { firebaseApp } from "../../../api/bearer-api";
import { error } from "../../../app/log";
import { getFunctions, httpsCallable } from 'firebase/functions';
import { TUpdateProvidersApiIntegrationResponse } from "../models/updateProvidersApiIntegration";

const updateProvidersApiIntegration = async (strArray: string[]): Promise<TUpdateProvidersApiIntegrationResponse | void> => { // void type added by Bearer Web Developer
    try {
        // add the location string as you call getFunctions
        const functions = getFunctions(firebaseApp, 'australia-southeast2');
        const t = httpsCallable<any, TUpdateProvidersApiIntegrationResponse>(functions, 'updateProvidersApiIntegration');
        const result = await t({ intermediate_api_providers: strArray });
        return result.data;
    } catch (err: any) {
        error(err); // modify by Bearer Web Developer
        error(err?.message || 'unknown error');   // throw new Error modified by Bearer Web Developer 
    }
}
export default updateProvidersApiIntegration;