// this file added by Bearer Web Developer (FullDiscountAutomaticallyState)
import { Instance, types } from "mobx-state-tree";

export const FullDiscountAutomaticallyState = types
    .model("FullDiscountAutomaticallyState", {
        is_full_discount: types.optional(types.boolean, false),
        coupon_id: types.optional(types.string, ''),
        fullDiscountUsedOnce: types.optional(types.boolean, false),
    })
    .actions(self => {
        return {
            setFullDiscount(value: boolean) {
                self.is_full_discount = value;
            },
            setCouponId(value: string) {
                self.coupon_id = value;
            },
            setFullDiscountUsedOnce(value: boolean) {
                self.fullDiscountUsedOnce = value;
            }
        }
    });

export interface IFullDiscountAutomaticallyState extends Instance<typeof FullDiscountAutomaticallyState> { };

