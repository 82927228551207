// this file added by Bearer Web Developer
import React, { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { useAppState } from '../../states/app-state';
import AddToHomeScreenPopup from './poup';
interface IBeforeInstallPromptEvent extends Event {
    readonly platforms: string[];
    readonly userChoice: Promise<{
        outcome: "accepted" | "dismissed";
        platform: string;
    }>;
    prompt(): Promise<void>;
}
const AddToHomeScreen: React.FunctionComponent = observer(() => {

    const [prompt, setPrompt] = React.useState<IBeforeInstallPromptEvent | null>(null);
    const appState = useAppState();
    function handleBeforeInstallPromptEvent(event: any) {
        event.preventDefault();
        if (prompt) return;
        setPrompt(event);
        // console.log("beforeinstallprompt event fired and captured");
    }
    useEffect(function () {
        if (!prompt) {
            window.addEventListener("beforeinstallprompt", handleBeforeInstallPromptEvent);
            return function cleanup() {
                window.removeEventListener("beforeinstallprompt", handleBeforeInstallPromptEvent);
            };
        } else {
            appState?.setAddToHomeScreen({
                message: "Add To Home Screen",
                handleClick: () => {
                    prompt?.prompt();
                }
            });
        }
    }, [prompt]);
    // added by Bearer Web Developer end
    return (<>{appState?.addToHomeScreen && <AddToHomeScreenPopup/>}</>);
});

export default AddToHomeScreen;
