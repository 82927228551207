/* this file added by Bearer Web Developer  */
import styles from './reservationSource.module.scss';
import { useAppState } from "../../../../../../../../states/app-state";

const ReservationSource = () => {
    const appState = useAppState();
    const intermediate_api_platform = appState?.ReservedOrders.activeManageOrder?.intermediate_api_platform;
    return (
        <div className={styles.reservation_source}>
            <img src="/language-black.webp" alt="" width={21} height={21} />
            <div>
                <h4>Reservation Source:</h4>
                <p>{intermediate_api_platform}</p>
            </div>
        </div>
    )
}
export default ReservationSource;