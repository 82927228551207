/* this file added by Bearer Web Developer  */
import React from 'react';
import styles from './orders.module.scss';
import Order from './order';
import { useAppState } from '../../../states/app-state';
import { TReservedOrderDetails } from '../../../states/reservedOrdersState/models/reservedOrderDetails';


const Orders: React.FC = () => {
    const appState = useAppState();
    const { reservedOrdersList } = appState.ReservedOrders;
    return (
        <div className={styles.orders}>
            {reservedOrdersList?.map((r: TReservedOrderDetails) => <Order key={r?.transport_id} reservedOrderInfo={r} />)}
        </div>
    )
}
export default Orders;