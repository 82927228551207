/* this file added by Bearer Web Developer  */
import { IAppState } from "../../../../../../../../states/app-state";

export function DestinationDetails(value: string) {
    if (value.length === 0) return "Destination Details is required";
}
export function RecipientName(value: string) {
    if (value.length === 0) return "Recipient’s Name is required";
}
export function RecipientPhoneNumberCode(value: string) {
    value = value?.slice(4);
    if (value.length === 0) return "Recipient’s Number is required (Beginning with +614)";
    if (!(value.length === 8)) return "8 Digits (Beginning with +614)";
    if (!value.match(/^[0-9]*$/)) return "8 Digits (Beginning with +614)";
}
export function RecipientPhoneNumberNone(value: string) {
    value = value?.slice(3);
    if (value.length === 0) return "Recipient’s Number is required (Beginning with +61)";
    if (!(value.length === 9)) return "9 Digits (Beginning with +61)";
    if (!value.match(/^[0-9]*$/)) return "9 Digits (Beginning with +61)";
}
export function validateForm(appState: IAppState) {
    const editData = appState?.ReservedOrders.editOrderApiIntegrationData;
    const RecipientPhoneNumber = editData?.dropoff_verification_method === "Code" ? RecipientPhoneNumberCode : RecipientPhoneNumberNone;
    if (!DestinationDetails(editData?.destination_details) && !RecipientName(editData?.recipient_name)
        && !RecipientPhoneNumber(editData?.recipient_phone_number)) {
        return true;
    }
}