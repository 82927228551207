/* this file added by Bearer Web Developer  */
import React from 'react';
import styles from './importantNote.module.scss';
import notes from './notes.json';
import { useAppState } from '../../../../../states/app-state';
import { observer } from 'mobx-react-lite';
import { ApiIntegrationEnum } from '../../../../../states/apiIntegrationState/models/apiIntegrationEnum';

const ImportantNote: React.FC = observer(() => {
    const appState = useAppState();
    const step = appState?.ApiIntegration?.apiIntegrationStep;
    const note = step === ApiIntegrationEnum.EnableApiIntegration ? notes?.enableNote :
        step === ApiIntegrationEnum.GenerateApiIntegration ? notes?.generateNote : notes?.regenerateNote;
    return (
        <div className={styles.important_note}>
            <h3><img src="/important.svg" alt="!" />Important note:</h3>
            <ul>{note?.map((n, i) => (<li key={i}>{n}</li>))}</ul>
        </div>
    )
});
export default ImportantNote;