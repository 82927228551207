// this file added by Bearer Web Developer (ApiIntegrationState)


import { flow, getEnv, getParent, Instance, onSnapshot, SnapshotIn, types, getParentOfType, cast, getSnapshot } from "mobx-state-tree";
import { AppState, EnvType, IAppState, MessageEnum, OrderState, useAppState } from "../app-state";

import { error } from "../../app/log";
import enableApiIntegration from "./enableApiIntegration";
import { TEnableApiIntegrationResponse } from "./models/enableApiIntegration";
import { TDisableApiIntegrationResponse } from "./models/disableApiIntegration";
import disableApiIntegration from "./disableApiIntegration";
import { TRegenerateApiIntegrationResponse } from "./models/regenerateApiIntegration";
import regenerateApiIntegration from "./regenerateApiIntegration";
import { initialApiIntegrationData, TApiIntegrationData, TApiIntegrationDataValue } from "./models/apiIntegrationData";
import updatePlatformsApiIntegration from "./updatePlatformsApiIntegration";
import { TUpdatePlatformsApiIntegrationResponse } from "./models/updatePlatformsApiIntegration";
import updateProvidersApiIntegration from "./updateProvidersApiIntegration";
import { TUpdateProvidersApiIntegrationResponse } from "./models/updateProvidersApiIntegration";
import { ApiIntegrationEnum } from "./models/apiIntegrationEnum";

export const ApiIntegrationState = types
    .model("ApiIntegrationState", {
        is_api_access_allowed: types.optional(types.boolean, false),
        api_access_not_allowed_reason: types.optional(types.string, ''),
        is_api_access_enabled_by_user: types.optional(types.boolean, false),
        switchIntegration: types.optional(types.boolean, false),
        loadingApi: types.optional(types.boolean, false),
        apiIntegrationData: types.maybeNull(types.frozen<TApiIntegrationData>()),
        apiIntegrationOpen: types.optional(types.boolean, false),
        apiIntegrationStep: types.optional(types.enumeration([...Object.values(ApiIntegrationEnum)]), ApiIntegrationEnum.EnableApiIntegration),
    })
    .views(self => ({
        // get is_api_access_allowed() {
        //     return self.is_api_access_allowed';
        // },
    }))
    .actions(self => {
        return {
            setLoadingApi(value: boolean) {
                self.loadingApi = value;
            },
            setApiIntegrationOpen(value: boolean) {
                self.apiIntegrationOpen = value;
            },
            setApiIntegrationData(value: TApiIntegrationDataValue) {
                self.apiIntegrationData = { ...initialApiIntegrationData, ...self.apiIntegrationData, ...value };
            },
            setApiIntegrationStep(value: ApiIntegrationEnum) {
                self.apiIntegrationStep = value;
            },
            setSwitchIntegration(value: boolean) {
                self.switchIntegration = value;
            },
        }
    })
    .actions(self => {
        return {
            fetchEnableApiIntegration: flow(function* () {
                const api = getEnv<EnvType>(self).api;
                if (self.is_api_access_enabled_by_user) return;
                ///// To solve AppCheck - WebApp Issue on Loading App State and Tabs if any
                api.callApiWithAppCheck();
                try {
                    const result: TEnableApiIntegrationResponse = yield enableApiIntegration();
                    console.log({ enableApiIntegration: result })
                    if (result.code === 200) {
                        // Update API Integration View with the received data + Enable Toggle
                        self.is_api_access_enabled_by_user = result?.is_api_access_enabled_by_user
                        self.switchIntegration = true;
                        const { api_key, api_secret } = result;
                        self.setApiIntegrationData({
                            api_key, api_secret,
                            allowed_intermediate_api_providers: [result?.allowed_intermediate_api_providers[0]], // First item cannot be removed
                            allowed_intermediate_api_platfroms: [result?.allowed_intermediate_api_platfroms[0]], // First item cannot be removed
                            intermediate_api_providers: [
                                ...result?.allowed_intermediate_api_providers.filter(((_, i) => (i > 0))) // All items except the first item can be removed
                            ],
                            intermediate_api_platfroms: [
                                ...result?.allowed_intermediate_api_platfroms.filter(((_, i) => (i > 0))) // All items except the first item can be removed
                            ],
                        });
                        self.setApiIntegrationStep(ApiIntegrationEnum.GenerateApiIntegration);

                    } else if (result.code === 401 || result.code === 499) {
                        // Show First View (Map) in Guest Mode (logout and close all popups)
                        self.setApiIntegrationOpen(false);
                        const appState = getParentOfType(self, AppState);
                        appState.login.logout();
                    } else if (result.code === 400) {
                        // Reset Toggle to Disabled
                        // Show Message + OK
                        // If OK clicked, Close API Integration View
                        self.switchIntegration = false;
                        self.setApiIntegrationStep(ApiIntegrationEnum.EnableApiIntegration);
                        const appState = getParentOfType(self, AppState);
                        appState.setSnackMessage({
                            message: result?.message || 'unknown error!',
                            handleClick: () => { self.setApiIntegrationOpen(false); }
                        })
                    } else if (result.code === 436) {
                        // Reset Toggle to Disabled
                        // Show Message + OK
                        // If OK clicked, Show Favourite Place (Saved Address) View
                        self.switchIntegration = false;
                        self.setApiIntegrationStep(ApiIntegrationEnum.EnableApiIntegration);
                        const appState = getParentOfType(self, AppState);
                        appState.setSnackMessage({
                            message: result?.message || 'unknown error!',
                            handleClick: () => {
                                appState.favouritePlaces?.setDefaultOriginFormOpen(true);
                            }
                        })
                    } else if (result.code === 406 || result.code === 408 || result.code === 500) {
                        // User Log out (First Action), Left Button (OK -> Reload App)
                        // Right Button (Support -> W.S.URL)
                        self.setApiIntegrationOpen(false);
                        const appState = getParentOfType(self, AppState);
                        appState.login.logout();
                        appState.setSnackMessage({
                            message: result?.message || 'unknown error!',
                            hasSopportLink: true,
                            handleClick: () => {
                                window.location.reload();
                            }
                        })
                    }
                } catch (err: any) {
                    error(err);
                }
            }),
            fetchDisableApiIntegration: flow(function* () {
                const api = getEnv<EnvType>(self).api;
                if (!self.is_api_access_enabled_by_user) return;
                ///// To solve AppCheck - WebApp Issue on Loading App State and Tabs if any
                api.callApiWithAppCheck();
                try {
                    const result: TDisableApiIntegrationResponse = yield disableApiIntegration();
                    console.log({ disableApiIntegrationresult: result })
                    if (result.code === 200) {
                        // Disable the Toggle, Update API Integration View
                        self.is_api_access_enabled_by_user = result?.is_api_access_enabled_by_user
                        self.switchIntegration = false;
                        self.setApiIntegrationStep(ApiIntegrationEnum.EnableApiIntegration);
                    } else if (result.code === 401 || result.code === 499) {
                        // Show First View (Map) in Guest Mode (logout and close all popups)
                        const appState = getParentOfType(self, AppState);
                        appState.ApiIntegration?.setApiIntegrationOpen(false);
                        appState.login.logout();
                    } else if (result.code === 400) {
                        // Show Message + OK, If OK clicked, Close API Integration View
                        const appState = getParentOfType(self, AppState);
                        appState.setSnackMessage({
                            message: result?.message || 'unknown error!',
                            handleClick: () => { self.setApiIntegrationOpen(false); }
                        })
                    } else if (result.code === 406 || result.code === 408 || result.code === 500) {
                        // User Log out (First Action), Left Button (OK -> Reload App)
                        // Right Button (Support -> W.S.URL)
                        self.setApiIntegrationOpen(false);
                        const appState = getParentOfType(self, AppState);
                        appState.login.logout().then(() => {
                            appState.setSnackMessage({
                                message: result?.message || 'unknown error!',
                                hasSopportLink: true,
                                handleClick: () => {
                                    window.location.reload();
                                }
                            })
                        }).catch(er => console.error(er));
                    }
                } catch (err: any) {
                    error(err);
                }
            }),
            fetchRegenerateApiIntegration: flow(function* () {
                const api = getEnv<EnvType>(self).api;
                if (!self.is_api_access_enabled_by_user) return;
                ///// To solve AppCheck - WebApp Issue on Loading App State and Tabs if any
                api.callApiWithAppCheck();
                try {
                    const result: TRegenerateApiIntegrationResponse = yield regenerateApiIntegration();
                    console.log({ regenerateApiIntegrationresult: result })
                    if (result.code === 200) {
                        // Update API Integration View with new API Key and Secret
                        self.is_api_access_enabled_by_user = result?.is_api_access_enabled_by_user
                        const { api_key, api_secret } = result;
                        self.setApiIntegrationData({ api_key, api_secret });
                        self.setApiIntegrationStep(ApiIntegrationEnum?.AfterRegenerateApiIntegration);
                    } else if (result.code === 401 || result.code === 499) {
                        // Show First View (Map) in Guest Mode (logout and close all popups)
                        const appState = getParentOfType(self, AppState);
                        appState.ApiIntegration?.setApiIntegrationOpen(false);
                        appState.login.logout();
                    } else if (result.code === 400) {
                        // Show Message + OK, If OK clicked, Close API Integration View
                        const appState = getParentOfType(self, AppState);
                        appState.setSnackMessage({
                            message: result?.message || 'unknown error!',
                            handleClick: () => { self.setApiIntegrationOpen(false); }
                        })
                    } else if (result.code === 406 || result.code === 408 || result.code === 500) {
                        // User Log out (First Action), Left Button (OK -> Reload App)
                        // Right Button (Support -> W.S.URL)
                        self.setApiIntegrationOpen(false);
                        const appState = getParentOfType(self, AppState);
                        appState.login.logout().then(() => {
                            appState.setSnackMessage({
                                message: result?.message || 'unknown error!',
                                hasSopportLink: true,
                                handleClick: () => {
                                    window.location.reload();
                                }
                            })
                        }).catch(er => console.error(er));
                    }
                } catch (err: any) {
                    error(err);
                }
            }),
            fetchUpdatePlatformsApiIntegration: flow(function* (strArray) {
                const api = getEnv<EnvType>(self).api;
                if (!self.is_api_access_allowed || !self.is_api_access_enabled_by_user) return;
                ///// To solve AppCheck - WebApp Issue on Loading App State and Tabs if any
                api.callApiWithAppCheck();
                try {
                    const result: TUpdatePlatformsApiIntegrationResponse = yield updatePlatformsApiIntegration(strArray);
                    if (result.code === 200) {
                        // Update API Integration View with the Object
                        console.log({ updatePlatformsApiIntegrationresult: result })
                        self?.setApiIntegrationData({
                            allowed_intermediate_api_platfroms: [result?.allowed_intermediate_api_platfroms[0]], // First item cannot be removed
                            intermediate_api_platfroms: [
                                ...result?.allowed_intermediate_api_platfroms.filter(((_, i) => (i > 0))) // All items except the first item can be removed
                            ],
                        })
                    } else if (result.code === 401 || result.code === 499) {
                        // Show First View (Map) in Guest Mode (logout and close all popups)
                        const appState = getParentOfType(self, AppState);
                        appState.ApiIntegration?.setApiIntegrationOpen(false);
                        appState.login.logout();
                    } else if (result.code === 400) {
                        // Show Message + OK, If OK clicked, Close API Integration View
                        const appState = getParentOfType(self, AppState);
                        appState.setSnackMessage({
                            message: result?.message || 'unknown error!',
                            handleClick: () => { self.setApiIntegrationOpen(false); }
                        })
                    } else if (result.code === 406 || result.code === 408 || result.code === 500) {
                        // User Log out (First Action), Left Button (OK -> Reload App)
                        // Right Button (Support -> W.S.URL)
                        self.setApiIntegrationOpen(false);
                        const appState = getParentOfType(self, AppState);
                        appState.login.logout().then(() => {
                            appState.setSnackMessage({
                                message: result?.message || 'unknown error!',
                                hasSopportLink: true,
                                handleClick: () => {
                                    window.location.reload();
                                }
                            })
                        }).catch(er => console.error(er));
                    }
                } catch (err: any) {
                    error(err);
                }
            }),
            fetchUpdateProvidersApiIntegration: flow(function* (strArray) {
                const api = getEnv<EnvType>(self).api;
                if (!self.is_api_access_allowed || !self.is_api_access_enabled_by_user) return;
                ///// To solve AppCheck - WebApp Issue on Loading App State and Tabs if any
                api.callApiWithAppCheck();
                try {
                    const result: TUpdateProvidersApiIntegrationResponse = yield updateProvidersApiIntegration(strArray);
                    if (result.code === 200) {
                        // Update API Integration View with the Object
                        console.log({ updateProvidersApiIntegrationresult: result })
                        self?.setApiIntegrationData({
                            allowed_intermediate_api_providers: [result?.allowed_intermediate_api_providers[0]], // First item cannot be removed
                            intermediate_api_providers: [
                                ...result?.allowed_intermediate_api_providers.filter(((_, i) => (i > 0))) // All items except the first item can be removed
                            ],
                        })
                    } else if (result.code === 401 || result.code === 499) {
                        // Show First View (Map) in Guest Mode (logout and close all popups)
                        const appState = getParentOfType(self, AppState);
                        appState.ApiIntegration?.setApiIntegrationOpen(false);
                        appState.login.logout();
                    } else if (result.code === 400) {
                        // Show Message + OK, If OK clicked, Close API Integration View
                        const appState = getParentOfType(self, AppState);
                        appState.setSnackMessage({
                            message: result?.message || 'unknown error!',
                            handleClick: () => { self.setApiIntegrationOpen(false); }
                        })
                    } else if (result.code === 406 || result.code === 408 || result.code === 500) {
                        // User Log out (First Action), Left Button (OK -> Reload App)
                        // Right Button (Support -> W.S.URL)
                        self.setApiIntegrationOpen(false);
                        const appState = getParentOfType(self, AppState);
                        appState.login.logout().then(() => {
                            appState.setSnackMessage({
                                message: result?.message || 'unknown error!',
                                hasSopportLink: true,
                                handleClick: () => {
                                    window.location.reload();
                                }
                            })
                        }).catch(er => console.error(er));
                    }
                } catch (err: any) {
                    error(err);
                }
            })
        }
    })
export interface IApiIntegrationState extends Instance<typeof ApiIntegrationState> { };

