import React from "react";
import { Avatar, Button, Menu, MenuItem } from "@material-ui/core";
import ProfileEditor from "../../containers/profile/ProfileEditorX";
import { useAppState } from "../../states/app-state";
import { observer } from "mobx-react-lite";
import { fetchDownloadUrl } from "../../api/bearer-api";
import { useEffect } from "react";
import styles from './ProfileMenu.module.scss';  // added by Bearer Web Developer
import SettingPopup from "../../containers/setting/SettingPopup"; // added by Bearer Web Developer
import LogoutConfirm from "./logoutConfirm"; // added by Bearer Web Developer
import ApiIntegration from "./apiIntegration"; // added by Bearer Web Developer 

const ProfileMenu: React.FC = observer(() => {

    // added by Bearer Web Developer start
    const appState = useAppState();
    const [editSettingOpen, setEditSettingOpen] = React.useState(false);
    const handleEditSetting = () => {
        appState.profile.fetchProfile();
        setEditSettingOpen(true);
        handleClose();
    }
    const handleEditSettingClose = () => {
        setEditSettingOpen(false);
    }
    // added by Bearer Web Developer end
    // added by Bearer Web Developer start (API Integration)
    const { apiIntegrationOpen, setApiIntegrationOpen } = appState?.ApiIntegration;
    const handleApiIntegration = () => {
        if (appState.ApiIntegration?.is_api_access_allowed) {
            setApiIntegrationOpen(true);
        } else {
            appState.setSnackMessage({
                message: appState?.ApiIntegration?.api_access_not_allowed_reason || 'unknown error!',
            })
        }
        handleClose();
    }
    const handleApiIntegrationClose = () => {
        setApiIntegrationOpen(false);
    }
    // added by Bearer Web Developer end (API Integration)
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const [editProfileOpen, setEditProfileOpen] = React.useState(false);
    const [logoutConfirmOpen, setLogoutConfirmOpen] = React.useState(false); // added by Bearer Web Developer
    const handleEditProfile = () => {
        setEditProfileOpen(true);
        handleClose();
    }
    const handleEditProfileClose = () => {
        setEditProfileOpen(false);
    }
    // added by Bearer Web Developer start
    const handleLogoutConfirmClose = () => {
        setLogoutConfirmOpen(false);
    }
    const handleLogoutConfirm = () => {
        setLogoutConfirmOpen(true);
        handleClose();
    }
    // added by Bearer Web Developer end
    // commented by Bearer Web Developer start
    // const handleLogout = () => {
    //     handleClose();
    //     appState.login.logout();
    // }
    // commented by Bearer Web Developer end
    const [preview, setPreview] = React.useState("")

    useEffect(() => {
        const image = appState.profile.avatar;
        if (image) {
            (async () => {
                const result = await fetchDownloadUrl(image);
                if (result) {
                    setPreview(result);
                }
            })();
        } else {
            setPreview('');
        }
    }, [setPreview, appState.profile.avatar])
    // modified by Bearer Web Developer start
    return (<>
        <ProfileEditor open={editProfileOpen} handleClose={handleEditProfileClose} />
        <SettingPopup open={editSettingOpen} handleClose={handleEditSettingClose} />
        {logoutConfirmOpen && <LogoutConfirm open={logoutConfirmOpen} handleClose={handleLogoutConfirmClose} />} {/* added by Bearer Web Developer */}
        {apiIntegrationOpen && <ApiIntegration open={apiIntegrationOpen} handleClose={handleApiIntegrationClose} />} {/* added by Bearer Web Developer */}
        <div className={styles.profile_part}>
            <Button
                id="basic-button"
                aria-controls={open ? 'basic-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClick}
                disableRipple={true}
                className={styles.profile_button}>
                {!preview &&
                    <span className={styles.profile}>
                        <span className={styles.profile_person}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="19" height="19" viewBox="0 0 19 19" fill="none">
                                <path d="M9.49984 9.5013C8.629 9.5013 7.88352 9.19123 7.26338 8.57109C6.64324 7.95095 6.33317 7.20547 6.33317 6.33464C6.33317 5.4638 6.64324 4.71832 7.26338 4.09818C7.88352 3.47804 8.629 3.16797 9.49984 3.16797C10.3707 3.16797 11.1162 3.47804 11.7363 4.09818C12.3564 4.71832 12.6665 5.4638 12.6665 6.33464C12.6665 7.20547 12.3564 7.95095 11.7363 8.57109C11.1162 9.19123 10.3707 9.5013 9.49984 9.5013ZM3.1665 15.8346V13.618C3.1665 13.1694 3.28196 12.757 3.51286 12.381C3.74376 12.0049 4.05053 11.718 4.43317 11.5201C5.25123 11.111 6.08248 10.8043 6.92692 10.5997C7.77136 10.3952 8.629 10.293 9.49984 10.293C10.3707 10.293 11.2283 10.3952 12.0728 10.5997C12.9172 10.8043 13.7484 11.111 14.5665 11.5201C14.9491 11.718 15.2559 12.0049 15.4868 12.381C15.7177 12.757 15.8332 13.1694 15.8332 13.618V15.8346H3.1665Z" fill="#14438E" />
                            </svg>
                        </span>
                        <span>{appState.profile.firstName} {appState.profile.lastName}</span>
                        <span className={styles.expand_more}>
                            <svg className={`${open ? styles.open : ''}`} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                <path d="M12 15.3746L6 9.37461L7.4 7.97461L12 12.5746L16.6 7.97461L18 9.37461L12 15.3746Z" fill="#14438E" />
                            </svg>
                        </span>
                    </span>
                }
                {preview && <Avatar src={preview} className={''} />}
            </Button>
            <div className={styles.bar}></div>
            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{ 'aria-labelledby': 'basic-button' }}
                style={{ top: "55px", borderRadius: "10px" }}
                PaperProps={{
                    style: {
                        background: "var(--neutral-hsb-89, #E3E3E3)",
                        boxShadow: "0px 38px 31px -27px rgba(53, 53, 53, 0.37)"
                    }
                }}
            >
                <MenuItem style={{ padding: "0 14px" }} className={styles.menu_item} disabled><span className={styles.text}>{appState.profile.firstName} {appState.profile.lastName}</span></MenuItem>
                <MenuItem style={{ padding: "0 14px" }} className={styles.menu_item} onClick={handleEditProfile}><span className={styles.text}>Edit Profile</span></MenuItem>
                <MenuItem style={{ padding: "0 14px" }} className={styles.menu_item} onClick={handleApiIntegration}><span className={styles.text}>API Integration</span></MenuItem>  {/* added by Bearer Web Developer */}
                <MenuItem style={{ padding: "0 14px" }} className={styles.menu_item} onClick={handleEditSetting}><span className={styles.text}>Email and notifications</span></MenuItem>
                <MenuItem style={{ padding: "0 14px" }} className={styles.menu_item} button component="a" href={appState.webAppRemoteConfigParameters?.supportUrl} target="_blank">
                    <span className={styles.text}>Support</span>
                </MenuItem>
                <MenuItem style={{ padding: "0 14px" }} className={styles.menu_item} button component="a" href={appState.webAppRemoteConfigParameters?.businessCustomerLegalUrl} target="_blank">
                    <span className={styles.text}>Legal</span>
                </MenuItem>
                <MenuItem style={{ padding: "0 14px" }} className={styles.menu_item} onClick={handleLogoutConfirm}><span className={styles.text_end}>Log out</span></MenuItem>
            </Menu>
        </div>
    </>);
    // modified by Bearer Web Developer end
})
export default ProfileMenu;